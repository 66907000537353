<template>
    <div class="FooterDomun">
        <footer class="grid_app" :class="{ 'fixed-bottom': ocultarElementos }">
            <div></div>
            <div>
                <template v-if="!ocultarElementos">
                    <div class="brand">
                        <img src="@/assets/img/general_logo_domun.webp" class="img-fluid" alt="logo" loading="lazy">
                    </div>
                    <div class="info">
                        <div>
                            <p class="title">Sobre Nosotros</p>
                            <p class="sub_text">
                                Somos una empresa dedicada al suministro, venta, instalación y
                                mantenimiento de sistemas de aire acondicionado, residencial, comercial e industrial,
                                con el objetivo de satisfacerlas necesidades actuales y futuras de nuestros clientes.
                            </p>
                        </div>
                        <div>
                            <p class="title">Contáctenos</p>
                            <a href="mailto:comercial@domunsas.com" class="text-decoration-none"
                                title="comercial@domunsas.com">
                                <p class="sub_text">comercial@domunsas.com</p>
                            </a>
                            <a href="tel:+573223019638" class="text-decoration-none" title="+57 3223019638">
                                <p class="sub_text">+57 3223019638</p>
                            </a>
                            <p class="sub_text" title="Carrera 10 No. 7 - 28 Tauramena, Casanare.">Carrera 10 No. 7 - 28
                                Tauramena, Casanare.</p>
                        </div>
                        <div>
                            <p class="title">Acerca de</p>
                            <router-link :to="{ name: 'AcercaDePreguntasFrecuentes' }" class="text-decoration-none"
                                title="Preguntas frecuentes">
                                <p class="sub_text">Preguntas frecuentes</p>
                            </router-link>
                            <router-link :to="{ name: 'AcercaDeTerminosCondiciones' }" class="text-decoration-none"
                                title="Términos y condiciones">
                                <p class="sub_text">Términos y condiciones</p>
                            </router-link>
                            <router-link :to="{ name: 'AcercaDePoliticaCookies' }" class="text-decoration-none"
                                title="Política y configuración de cookies">
                                <p class="sub_text">Política y configuración de cookies</p>
                            </router-link>
                        </div>
                        <div class="location">
                            <p class="title">¿Cómo encontrarnos?</p>
                            <div class="ratio ratio-16x9 mb-3" title="¿Cómo encontrarnos?">
                                <iframe
                                    src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d702.6051513699945!2d-72.74754039962563!3d5.016601058716539!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e6ad848db06fc0d%3A0xacdc204e934f68f5!2sCra.%2010%20%237-28%2C%20Tauramena%2C%20Casanare!5e0!3m2!1ses!2sco!4v1714168428881!5m2!1ses!2sco"
                                    allowfullscreen="" loading="lazy"
                                    referrerpolicy="no-referrer-when-downgrade"></iframe>
                            </div>
                        </div>
                    </div>
                </template>
                <div class="social_media" :class="{ 'mb-0 mt-1': ocultarElementos }">
                    <ul>
                        <a href="https://www.instagram.com/domunsoluciones?igsh=aGJsMGRpMjJpZnph" target="_blank"
                            title="Instagram">
                            <li>
                                <img src="@/assets/img/footer_icono_instagram.webp" width="16px" height="16px"
                                    alt="icono-instagram" loading="lazy" />
                            </li>
                        </a>
                        <a href="https://www.facebook.com/profile.php?id=100089628498632&mibextid=ZbWKwL"
                            target="_blank" title="Facebook">
                            <li>
                                <img src="@/assets/img/footer_icono_facebook.webp" width="8px" height="16px"
                                    alt="icono-facebook" loading="lazy" />
                            </li>
                        </a>
                        <a href="https://wa.me/573024906983" target="_blank" title="WhatsApp">
                            <li>
                                <img src="@/assets/img/footer_icono_whatsapp.webp" width="15px" height="15px"
                                    alt="icono-whatsapp" loading="lazy" />
                            </li>
                        </a>
                        <li>
                            <span :title="`Copyright © ${yearCurrent} domunshop.com`">Copyright © {{ yearCurrent }} domunshop.com</span>
                        </li>
                    </ul>
                </div>
            </div>
            <div></div>
        </footer>
    </div>
</template>

<script>
import moment from 'moment';
export default {
    name: "FooterDomun",

    data() {
        return {
            yearCurrent: moment().year()
        }
    },

    computed: {
        ocultarElementos() {
            if (
                this.$route.name === 'Login' ||
                this.$route.name === 'Registrarse' ||
                this.$route.name === 'NotFound'
            ) {
                return true
            }
            return false
        }
    },
}
</script>

<style scoped>
footer {
    width: 100%;
    height: auto;
    background-color: var(--colorBlanco);
}

.brand {
    display: grid;
    place-items: center;
    height: 110px;
    border-bottom: 2px solid var(--colorseccion1);
}

.brand img {
    width: 244px;
    height: 45px;
}

.info {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    width: 100%;
    gap: 2rem;
    height: auto;
    border-bottom: 2px solid var(--colorseccion1);
}

.title {
    color: var(--colorSecundario);
    font-weight: 900;
    font-style: normal;
    padding-top: 10px;
}

.sub_text {
    color: var(--colorTexto2);
    font-weight: 400;
    font-style: normal;
}

.location iframe {
    display: grid;
    place-items: center;
    margin-bottom: 10px;
}

.social_media {
    display: flex;
    align-items: center;
    height: 65px;
}

.social_media ul {
    display: flex;
    align-items: center;
    padding: 0;
    list-style-type: none;
}

.social_media li {
    margin-right: 15px;
}

.social_media li span {
    color: var(--colorTexto2);
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 15px;
}

.social_media li img {
    cursor: pointer;
    transition: transform 0.3s ease;
}

.social_media li img:hover {
    transform: scale(1.05);
}

@media screen and (max-width: 768px) {
    footer {
        padding-left: 10px;
        padding-right: 10px;
    }

    .social_media {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 65px;
        padding: 0 10px;
    }

    .social_media ul {
        display: flex;
        align-items: center;
        list-style: none;
        padding: 0;
        margin: 0;
    }

    .social_media span {
        white-space: nowrap;
    }
}

@media screen and (max-width: 360px) {
    .social_media ul {
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        padding: 0;
        margin: 0 auto;
    }

    .social_media li span {
        display: block;
    }
}

@media screen and (max-width: 280px) {
    .social_media li span {
        font-size: 13px;
    }
}
</style>