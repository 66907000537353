<template>
    <div class="NavbarBolsa">
        <router-link :to="{ name: 'Bolsa' }">
            <div class="position-relative" title="Bolsa de Compras">
                <img src="@/assets/img/general_icono_bolsa.webp" alt="icono-bolsa" loading="lazy" />
                <span v-if="cantidad > 0"
                    class="position-absolute top-0 start-75 translate-middle badge rounded-pill bg-danger">
                    {{ cantidad }}
                </span>
            </div>
        </router-link>
    </div>
</template>

<script>
import { auth, db } from "@/firebase";
import { collection, query, where, limit, onSnapshot, orderBy } from "firebase/firestore";
export default {
    name: "NavbarBolsa",

    data() {
        return {
            cantidad: 0,
        }
    },

    async created() {
        let usuario = auth.currentUser;
        if (usuario) {
            let usuarioDB = await this.$store.state.usuario.funciones.obtener(usuario.uid);
            if (usuarioDB) {
                await this.obtenerOrden();
            }
        }
    },

    methods: {
        async obtenerOrden() {
            const q = query(
                collection(db, "ordenes"),
                where("registro.idUsuario", "==", auth.currentUser.uid),
                where("estado", "==", "Pendiente"),
                orderBy("registro.creacion", "desc"),
                limit(1)
            );
            onSnapshot(q, async (querySnapshot) => {
                this.cantidad = querySnapshot.docs.length > 0 ? await this.$store.state.orden.producto.funciones.obtenerCantidad(querySnapshot.docs[0].data().id) : 0;
            });
        },
    }
}
</script>

<style scoped>
button {
    background: transparent;
}

img {
    cursor: pointer;
    width: 24.54px;
    height: 26.94px;
    transition: transform 0.3s ease;
}

img:hover {
    transform: scale(1.05);
}

@media screen and (max-width: 768px) {
    img {
        width: 25px;
        filter: brightness(0) saturate(100%) invert(98%) sepia(1%) saturate(184%) hue-rotate(113deg) brightness(116%) contrast(100%);
    }
}
</style>