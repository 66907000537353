import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
import { getFunctions } from 'firebase/functions';
import { getMessaging } from "firebase/messaging";

const firebaseApp = initializeApp({
    apiKey: "AIzaSyBrWuN-U1UVbdc5RLbes3xV3TAGs1c2toQ",
    authDomain: "domun-sr.firebaseapp.com",
    projectId: "domun-sr",
    storageBucket: "domun-sr.appspot.com",
    messagingSenderId: "790642848569",
    appId: "1:790642848569:web:a297c172c14dfe7a69aee8",
    measurementId: "G-N96TMP1ZXK"
});

getAnalytics();

const auth = getAuth(firebaseApp);
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);
const functions = getFunctions(firebaseApp);
const messaging = getMessaging(firebaseApp);

export {
    firebaseApp,
    auth,
    db,
    storage,
    functions,
    messaging
};