<template>
    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasMenuCategorias"
        aria-labelledby="offcanvasMenuCategoriasLabel">
        <div class="offcanvas-header">
            <div class="d-flex align-items-center w-100" data-bs-toggle="offcanvas" href="#offcanvasMenuPrincipal"
                role="button" aria-controls="offcanvasMenuPrincipal" title="Regresar">
                <i class="fas fa-chevron-left me-3"></i>
                <h5 class="offcanvas-title" id="offcanvasMenuCategorias">{{ categoria }}</h5>
            </div>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close" title="Cerrar"></button>
        </div>
        <div class="offcanvas-body">
            <Marcas v-if="categoriaTemp.conFormato" :categoria="categoriaTemp" />
        </div>
    </div>
</template>

<script>
import Marcas from './Marcas.vue';
export default {
    name: "NavbarCategoriasMenu",

    components: {
        Marcas
    },

    props: {
        categoria: String,
    },

    data() {
        return {
            categoriaTemp: {
                conFormato: "",
                sinFormato: "",
            },
        }
    },

    async created() {
        let url = await this.$store.state.producto.funciones.cambiarNombre(this.categoria);
        this.categoriaTemp = {
            conFormato: url,
            sinFormato: this.categoria
        }
    },

    mounted() {
        document.getElementById(`offcanvasMenuCategorias`).addEventListener('hidden.bs.offcanvas', event => {
            if (event.target.id === `offcanvasMenuCategorias`) {
                this.$emit("cerrar");
            }
        });
    },
}
</script>

<style scoped>
.offcanvas-body {
    padding: 0px !important;
}
</style>