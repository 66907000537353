<template>
    <div class="NavbarFavoritos">
        <router-link :to="{ name: 'PerfilFavoritos' }" title="Favoritos">
            <img src="@/assets/img/general_icono_favoritos.webp" alt="icono-favoritos" loading="lazy" />
        </router-link>
    </div>
</template>

<script>
export default {
    name: "NavbarFavoritos"
}
</script>

<style scoped>
img {
    cursor: pointer;
    width: 27px;
    height: 24px;
    transition: transform 0.3s ease;
}

img:hover {
    transform: scale(1.05);
}

@media screen and (max-width: 768px) {
    img {
        width: 25px;
        filter: brightness(0) saturate(100%) invert(98%) sepia(1%) saturate(184%) hue-rotate(113deg) brightness(116%) contrast(100%);
    }
}
</style>