import Vue from "vue";
import VueRouter from "vue-router";
import Vuelidate from "vuelidate";
import { auth } from "@/firebase";
import store from "@/store/index.js";
import Swal from "sweetalert2";
import VueGoodTablePlugin from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";
import "@/../public/css/vue-good-table.css";
Vue.use(VueRouter);
Vue.use(Vuelidate);
Vue.use(VueGoodTablePlugin);
const DynamicWrapper = {
  functional: true,
  render(h, { props, data }) {
    const component = props.component;
    return h(component, data);
  },
};

const routes = [
  /* LOADING TO - SERVICE WORKER */
  {
    path: "/",
    name: "LoadingTo",
    component: () => import("../views/LoadingTo.vue"),
    meta: {
      requiereAuth: false,
    },
  },
  /* 404 - NOT FOUND */
  {
    path: "*",
    name: "NotFound",
    component: () => import("../views/404.vue"),
    meta: {
      requiereAuth: false,
      title: `404 | ${store.state.titulo}`,
    },
  },
  /* INICIO */
  {
    path: "/inicio",
    name: "Inicio",
    component: () => import("../views/Inicio.vue"),
    meta: {
      requiereAuth: false,
      title: `Inicio | ${store.state.titulo}`,
    },
  },
  // PRODUCTOS GENERALES POR PAGINA
  {
    path: "/productos/p/:pagina",
    name: "ProductosPaginados",
    component: () => import("../views/Inicio.vue"),
    meta: {
      requiereAuth: false,
      title: `Inicio | ${store.state.titulo}`,
    },
  },
  // PRODUCTOS POR MARCAS
  {
    path: "/productos/marcas/:marca",
    name: "ProductosMarcas",
    component: () => import("../views/Productos/Marcas.vue"),
    meta: {
      requiereAuth: false,
    },
  },
  // PRODUCTOS POR MARCAS POR PAGINA
  {
    path: "/productos/marcas/:marca/p/:pagina",
    name: "ProductosMarcasPaginados",
    component: () => import("../views/Productos/Marcas.vue"),
    meta: {
      requiereAuth: false,
    },
  },
  // PRODUCTOS POR CATEGORIA Y MARCA
  {
    path: "/productos/categorias/:categoria/marcas/:marca",
    name: "ProductosCategoriasMarcas",
    component: () => import("../views/Productos/CategoriasMarcas.vue"),
    meta: {
      requiereAuth: false,
    },
  },
  // PRODUCTOS POR CATEGORIA Y MARCA POR PAGINA
  {
    path: "/productos/categorias/:categoria/marcas/:marca/p/:pagina",
    name: "ProductosCategoriasMarcasPaginados",
    component: () => import("../views/Productos/CategoriasMarcas.vue"),
    meta: {
      requiereAuth: false,
    },
  },
  // PRODUCTOS POR CATEGORIA
  {
    path: "/productos/categorias/:categoria",
    name: "ProductosCategorias",
    component: () => import("../views/Productos/Categorias.vue"),
    meta: {
      requiereAuth: false,
    },
  },
  // PRODUCTOS POR CATEGORIA POR PAGINA
  {
    path: "/productos/categorias/:categoria/p/:pagina",
    name: "ProductosCategoriasPaginados",
    component: () => import("../views/Productos/Categorias.vue"),
    meta: {
      requiereAuth: false,
    },
  },
  // RESULTADOS DE BUSQUEDA
  {
    path: "/productos/busqueda/:busqueda",
    name: "ProductosResultadosBusqueda",
    component: () => import("../views/Productos/ResultadosBusqueda.vue"),
    meta: {
      requiereAuth: false,
    },
  },
  // RESULTADOS DE BUSQUEDA POR PAGINA
  {
    path: "/productos/busqueda/:busqueda/p/:pagina",
    name: "ProductosResultadosBusquedaPaginados",
    component: () => import("../views/Productos/ResultadosBusqueda.vue"),
    meta: {
      requiereAuth: false,
    },
  },
  /* LOGIN */
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Usuario/Login.vue"),
    meta: {
      requiereAuth: false,
      title: `Iniciar Sesión | ${store.state.titulo}`,
    },
  },
  /* REGISTRARSE */
  {
    path: "/registrarse",
    name: "Registrarse",
    component: () => import("../views/Usuario/Registrarse.vue"),
    meta: {
      requiereAuth: false,
      title: `Registrarse | ${store.state.titulo}`,
    },
  },
  /* ACERCA DE */
  /* SOBRE NOSOTROS */
  {
    path: "/acercade/sobrenosotros",
    name: "AcercaDeSobreNosotros",
    component: () => import("../views/AcercaDe/SobreNosotros.vue"),
    meta: {
      requiereAuth: false,
      title: `Sobre Nosotros | ${store.state.titulo}`,
    },
  },
  /* PREGUNTAS FRECUENTES */
  {
    path: "/acercade/preguntasfrecuentes",
    name: "AcercaDePreguntasFrecuentes",
    component: () => import("../views/AcercaDe/PreguntasFrecuentes.vue"),
    meta: {
      requiereAuth: false,
      title: `Preguntas Frecuentes | ${store.state.titulo}`,
    },
  },
  /* TERMINOS Y CONDICIONES */
  {
    path: "/acercade/terminoscondiciones",
    name: "AcercaDeTerminosCondiciones",
    component: () => import("../views/AcercaDe/TerminosCondiciones.vue"),
    meta: {
      requiereAuth: false,
      title: `Términos y Condiciones | ${store.state.titulo}`,
    },
  },
  /* POLÍTICA Y CONFIGURACIÓN DE COOKIES */
  {
    path: "/acercade/politicacookies",
    name: "AcercaDePoliticaCookies",
    component: () => import("../views/AcercaDe/PoliticaCookies.vue"),
    meta: {
      requiereAuth: false,
      title: `Política y Configuración de Cookies | ${store.state.titulo}`,
    },
  },
  /* PANEL ADMINISTRATIVO */
  /* PRODUCTOS */
  {
    path: "/paneladministrativo/productos",
    name: "PanelAdministrativoProductos",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerUsuario = await store.state.usuario.funciones.obtener(
        auth.currentUser.uid
      );
      to.meta.component = () =>
        obtenerUsuario.rol === "Administrador"
          ? import("../views/PanelAdministrativo/Productos/Productos.vue")
          : import("../components/Avisos/Generales/SinPrivilegios.vue");
      to.meta.title =
        obtenerUsuario.rol === "Administrador"
          ? `Panel Administrativo | Productos | ${store.state.titulo}`
          : store.state.titulo;
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  {
    path: "/paneladministrativo/productos/nuevoproducto",
    name: "PanelAdministrativoProductosNuevo",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerUsuario = await store.state.usuario.funciones.obtener(
        auth.currentUser.uid
      );
      to.meta.component = () =>
        obtenerUsuario.rol === "Administrador"
          ? import("../views/PanelAdministrativo/Productos/NuevoProducto.vue")
          : import("../components/Avisos/Generales/SinPrivilegios.vue");
      to.meta.title =
        obtenerUsuario.rol === "Administrador"
          ? `Panel Administrativo | Productos | Nuevo Producto | ${store.state.titulo}`
          : store.state.titulo;
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  {
    path: "/paneladministrativo/productos/editarproducto/:id",
    name: "PanelAdministrativoProductosEditar",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerUsuario = await store.state.usuario.funciones.obtener(
        auth.currentUser.uid
      );
      if (obtenerUsuario.rol === "Administrador") {
        let obtenerProducto = await store.state.producto.funciones.obtener(
          to.params.id
        );
        to.meta.component = () =>
          obtenerProducto
            ? import(
                "../views/PanelAdministrativo/Productos/EditarProducto.vue"
              )
            : import("../components/Avisos/Productos/ProductoEliminado.vue");
        to.meta.title = obtenerProducto
          ? `Panel Administrativo | Productos | Editar Producto | ${store.state.titulo}`
          : store.state.titulo;
      } else {
        to.meta.component = () =>
          import("../components/Avisos/Generales/SinPrivilegios.vue");
        to.meta.title = store.state.titulo;
      }
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  /* ORDENES */
  {
    path: "/paneladministrativo/ordenes",
    name: "PanelAdministrativoOrdenes",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerUsuario = await store.state.usuario.funciones.obtener(
        auth.currentUser.uid
      );
      to.meta.component = () =>
        obtenerUsuario.rol === "Administrador"
          ? import("../views/PanelAdministrativo/Ordenes/Ordenes.vue")
          : import("../components/Avisos/Generales/SinPrivilegios.vue");
      to.meta.title =
        obtenerUsuario.rol === "Administrador"
          ? `Panel Administrativo | Órdenes | ${store.state.titulo}`
          : store.state.titulo;
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  {
    path: "/paneladministrativo/ordenes/verorden/:id",
    name: "PanelAdministrativoOrdenesVer",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerUsuario = await store.state.usuario.funciones.obtener(
        auth.currentUser.uid
      );
      if (obtenerUsuario.rol === "Administrador") {
        let obtenerOrden = await store.state.orden.registro.funciones.obtener(
          to.params.id,
          auth.currentUser.uid,
          true
        );
        to.meta.component = () =>
          obtenerOrden && obtenerOrden.estado !== "Pendiente"
            ? import("../views/PanelAdministrativo/Ordenes/VerOrden.vue")
            : import(
                "../components/Avisos/Ordenes/Administrador/OrdenEliminada.vue"
              );
        to.meta.title =
          obtenerOrden && obtenerOrden.estado !== "Pendiente"
            ? `Panel Administrativo | Órdenes | Ver Orden | ${store.state.titulo}`
            : store.state.titulo;
      } else {
        to.meta.component = () =>
          import("../components/Avisos/Generales/SinPrivilegios.vue");
        to.meta.title = store.state.titulo;
      }
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  /* USUARIOS */
  {
    path: "/paneladministrativo/usuarios",
    name: "PanelAdministrativoUsuarios",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerUsuario = await store.state.usuario.funciones.obtener(
        auth.currentUser.uid
      );
      to.meta.component = () =>
        obtenerUsuario.rol === "Administrador"
          ? import("../views/PanelAdministrativo/Usuarios/Usuarios.vue")
          : import("../components/Avisos/Generales/SinPrivilegios.vue");
      to.meta.title =
        obtenerUsuario.rol === "Administrador"
          ? `Panel Administrativo | Usuarios | ${store.state.titulo}`
          : store.state.titulo;
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  /* CARRUSEL */
  {
    path: "/paneladministrativo/carrusel",
    name: "PanelAdministrativoCarrusel",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerUsuario = await store.state.usuario.funciones.obtener(
        auth.currentUser.uid
      );
      to.meta.component = () =>
        obtenerUsuario.rol === "Administrador"
          ? import("../views/PanelAdministrativo/Carrusel/Carrusel.vue")
          : import("../components/Avisos/Generales/SinPrivilegios.vue");
      to.meta.title =
        obtenerUsuario.rol === "Administrador"
          ? `Panel Administrativo | Carrusel de Imágenes | ${store.state.titulo}`
          : store.state.titulo;
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  /* ACERCA DE */
  {
    path: "/paneladministrativo/acercade/sobrenosotros",
    name: "PanelAdministrativoSobreNosotros",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerUsuario = await store.state.usuario.funciones.obtener(
        auth.currentUser.uid
      );
      to.meta.component = () =>
        obtenerUsuario.rol === "Administrador"
          ? import("../views/PanelAdministrativo/AcercaDe/SobreNosotros.vue")
          : import("../components/Avisos/Generales/SinPrivilegios.vue");
      to.meta.title =
        obtenerUsuario.rol === "Administrador"
          ? `Panel Administrativo | Acerca de | Sobre Nosotros | ${store.state.titulo}`
          : store.state.titulo;
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  {
    path: "/paneladministrativo/acercade/preguntasfrecuentes",
    name: "PanelAdministrativoPreguntasFrecuentes",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerUsuario = await store.state.usuario.funciones.obtener(
        auth.currentUser.uid
      );
      to.meta.component = () =>
        obtenerUsuario.rol === "Administrador"
          ? import(
              "../views/PanelAdministrativo/AcercaDe/PreguntasFrecuentes.vue"
            )
          : import("../components/Avisos/Generales/SinPrivilegios.vue");
      to.meta.title =
        obtenerUsuario.rol === "Administrador"
          ? `Panel Administrativo | Acerca de | Preguntas Frecuentes | ${store.state.titulo}`
          : store.state.titulo;
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  {
    path: "/paneladministrativo/acercade/politicacookies",
    name: "PanelAdministrativoPoliticaCookies",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerUsuario = await store.state.usuario.funciones.obtener(
        auth.currentUser.uid
      );
      to.meta.component = () =>
        obtenerUsuario.rol === "Administrador"
          ? import("../views/PanelAdministrativo/AcercaDe/PoliticaCookies.vue")
          : import("../components/Avisos/Generales/SinPrivilegios.vue");
      to.meta.title =
        obtenerUsuario.rol === "Administrador"
          ? `Panel Administrativo | Acerca de | Política y Configuración de Cookies | ${store.state.titulo}`
          : store.state.titulo;
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  {
    path: "/paneladministrativo/acercade/terminoscondiciones",
    name: "PanelAdministrativoTerminosCondiciones",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerUsuario = await store.state.usuario.funciones.obtener(
        auth.currentUser.uid
      );
      to.meta.component = () =>
        obtenerUsuario.rol === "Administrador"
          ? import(
              "../views/PanelAdministrativo/AcercaDe/TerminosCondiciones.vue"
            )
          : import("../components/Avisos/Generales/SinPrivilegios.vue");
      to.meta.title =
        obtenerUsuario.rol === "Administrador"
          ? `Panel Administrativo | Acerca de | Términos y Condiciones | ${store.state.titulo}`
          : store.state.titulo;
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  /* PERFIL */
  /* INFORMACIÓN PERSONAL */
  {
    path: "/perfil/informacionpersonal",
    name: "PerfilInfoPersonal",
    component: () => import("../views/Perfil/InfoPersonal.vue"),
    meta: {
      requiereAuth: true,
      title: `Tu Perfil | Información Personal | ${store.state.titulo}`,
    },
  },
  /* DIRECCIONES */
  {
    path: "/perfil/direcciones",
    name: "PerfilDirecciones",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let tieneDirecciones =
        await store.state.direccion.funciones.obtenerRegistros(
          auth.currentUser.uid
        );
      to.meta.component = () =>
        tieneDirecciones.length === 0
          ? import("../components/Avisos/Direcciones/SinRegistros.vue")
          : import("../views/Perfil/Direcciones/Direcciones.vue");
      to.meta.title =
        tieneDirecciones.length === 0
          ? store.state.titulo
          : `Tu Perfil | Direcciones | ${store.state.titulo}`;
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  {
    path: "/perfil/direcciones/nuevadireccion",
    name: "PerfilDireccionesNuevo",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let limiteAlcanzado = await store.state.direccion.funciones.obtenerLimite(
        auth.currentUser.uid
      );
      to.meta.component = () =>
        limiteAlcanzado
          ? import("../components/Avisos/Direcciones/LimiteRegistros.vue")
          : import("../views/Perfil/Direcciones/NuevaDireccion.vue");
      to.meta.title = limiteAlcanzado
        ? store.state.titulo
        : `Tu Perfil | Direcciones | Nueva Dirección | ${store.state.titulo}`;
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  {
    path: "/perfil/direcciones/editardireccion/:id",
    name: "PerfilDireccionesEditar",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let existeRegistro = await store.state.direccion.funciones.validarUsuario(
        to.params.id,
        auth.currentUser.uid
      );
      to.meta.component = () =>
        !existeRegistro
          ? import("../components/Avisos/Direcciones/DireccionEliminada.vue")
          : import("../views/Perfil/Direcciones/EditarDireccion.vue");
      to.meta.title = !existeRegistro
        ? store.state.titulo
        : `Tu Perfil | Direcciones | Editar Dirección | ${store.state.titulo}`;
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  /* NOTIFICACIONES */
  {
    path: "/perfil/notificaciones",
    name: "PerfilNotificaciones",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let tieneNotificaciones =
        await store.state.notificacion.funciones.obtenerRegistros(
          auth.currentUser.uid
        );
      to.meta.component = () =>
        tieneNotificaciones.length === 0
          ? import("../components/Avisos/Notificaciones/SinRegistros.vue")
          : import("../views/Perfil/Notificaciones/Notificaciones.vue");
      to.meta.title =
        tieneNotificaciones.length === 0
          ? store.state.titulo
          : `Tu Perfil | Notificaciones | ${store.state.titulo}`;
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  {
    path: "/perfil/notificaciones/p/:pagina",
    name: "PerfilNotificacionesPaginados",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let tieneNotificaciones =
        await store.state.notificacion.funciones.obtenerRegistros(
          auth.currentUser.uid
        );
      to.meta.component = () =>
        tieneNotificaciones.length === 0
          ? import("../components/Avisos/Notificaciones/SinRegistros.vue")
          : import("../views/Perfil/Notificaciones/Notificaciones.vue");
      to.meta.title =
        tieneNotificaciones.length === 0
          ? store.state.titulo
          : `Tu Perfil | Notificaciones | ${store.state.titulo}`;
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  {
    path: "/perfil/notificaciones/vernotificacion/:id",
    name: "PerfilNotificacionesVer",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let existeRegistro =
        await store.state.notificacion.funciones.validarUsuario(
          to.params.id,
          auth.currentUser.uid
        );
      to.meta.component = () =>
        !existeRegistro
          ? import(
              "../components/Avisos/Notificaciones/NotificacionEliminada.vue"
            )
          : import("../views/Perfil/Notificaciones/VerNotificacion.vue");
      to.meta.title = !existeRegistro
        ? store.state.titulo
        : `Tu Perfil | Notificaciones | Ver Notificación | ${store.state.titulo}`;
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  /* ÓRDENES */
  {
    path: "/perfil/ordenes",
    name: "PerfilOrdenes",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let tieneOrdenes =
        await store.state.orden.registro.funciones.obtenerRegistros(
          auth.currentUser.uid,
          [...store.state.estadosOrden]
        );
      to.meta.component = () =>
        tieneOrdenes.length === 0
          ? import("../components/Avisos/Ordenes/Usuario/SinRegistros.vue")
          : import("../views/Perfil/Ordenes/Ordenes.vue");
      to.meta.title =
        tieneOrdenes.length === 0
          ? store.state.titulo
          : `Tu Perfil | Órdenes | ${store.state.titulo}`;
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  {
    path: "/perfil/ordenes/verorden/:id",
    name: "PerfilOrdenesVer",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerOrden = await store.state.orden.registro.funciones.obtener(
        to.params.id,
        auth.currentUser.uid
      );
      to.meta.component = () =>
        obtenerOrden && obtenerOrden.estado !== "Pendiente"
          ? import("../views/Perfil/Ordenes/VerOrden.vue")
          : import("../components/Avisos/Ordenes/Usuario/OrdenEliminada.vue");
      to.meta.title =
        obtenerOrden && obtenerOrden.estado !== "Pendiente"
          ? `Tu Perfil | Órdenes | Ver Orden | ${store.state.titulo}`
          : store.state.titulo;
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  /* FAVORITOS */
  {
    path: "/perfil/favoritos",
    name: "PerfilFavoritos",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let tieneFavoritos =
        await store.state.favorito.funciones.obtenerRegistros(
          auth.currentUser.uid
        );
      to.meta.component = () =>
        tieneFavoritos.length === 0
          ? import("../components/Avisos/Favoritos/SinRegistros.vue")
          : import("../views/Perfil/Favoritos.vue");
      to.meta.title =
        tieneFavoritos.length === 0
          ? store.state.titulo
          : `Tu Perfil | Favoritos | ${store.state.titulo}`;
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  /* FAVORITOS PAGINADOS */
  {
    path: "/perfil/favoritos/p/:pagina",
    name: "PerfilFavoritosPaginados",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let tieneFavoritos =
        await store.state.favorito.funciones.obtenerRegistros(
          auth.currentUser.uid
        );
      to.meta.component = () =>
        tieneFavoritos.length === 0
          ? import("../components/Avisos/Favoritos/SinRegistros.vue")
          : import("../views/Perfil/Favoritos.vue");
      to.meta.title =
        tieneFavoritos.length === 0
          ? store.state.titulo
          : `Tu Perfil | Favoritos | ${store.state.titulo}`;
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  /* CONFIGURACIÓN */
  {
    path: "/perfil/configuracion",
    name: "PerfilConfiguracion",
    component: () => import("../views/Perfil/Configuracion.vue"),
    meta: {
      requiereAuth: true,
      title: `Tu Perfil | Configuración | ${store.state.titulo}`,
    },
  },
  /* CAMBIAR CONTRASEÑA */
  {
    path: "/perfil/cambiarpassword",
    name: "PerfilCambiarPassword",
    component: () => import("../views/Perfil/CambiarPassword.vue"),
    meta: {
      requiereAuth: true,
      title: `Tu Perfil | Cambiar Contraseña | ${store.state.titulo}`,
    },
  },
  /* VISTA DEL PRODUCTO */
  {
    path: "/:nombre/p/:id",
    name: "ProductosVerProducto",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerProducto = await store.state.producto.funciones.obtener(
        to.params.id
      );
      to.meta.component = () =>
        obtenerProducto
          ? import("../views/Productos/VerProducto.vue")
          : import("../components/Avisos/Productos/ProductoEliminado.vue");
      to.meta.title = obtenerProducto
        ? `${obtenerProducto.nombre} | ${store.state.titulo}`
        : store.state.titulo;
      next();
    },
    meta: {
      requiereAuth: false,
      component: null,
      title: null,
    },
  },
  /* BOLSA */
  {
    path: "/bolsa",
    name: "Bolsa",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerUltimaOrden =
        await store.state.orden.registro.funciones.obtenerUltima(
          auth.currentUser.uid
        );
      to.meta.component = () =>
        obtenerUltimaOrden
          ? import("../views/Bolsa/Bolsa.vue")
          : import("../components/Avisos/Bolsa/SinRegistros.vue");
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: `Bolsa de Compras | ${store.state.titulo}`,
    },
  },
  /* BOLSA - SELECCIONAR DIRECCION */
  {
    path: "/bolsa/orden/:id/seleccionardireccion",
    name: "BolsaSeleccionarDireccion",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerUltimaOrden =
        await store.state.orden.registro.funciones.obtenerUltima(
          auth.currentUser.uid
        );
      let obtenerOrden = await store.state.orden.registro.funciones.obtener(
        to.params.id,
        auth.currentUser.uid
      );
      if (
        obtenerUltimaOrden &&
        obtenerOrden &&
        obtenerUltimaOrden.id === obtenerOrden.id
      ) {
        to.meta.component = () =>
          import("../views/Bolsa/Direcciones/SeleccionarDireccion.vue");
        to.meta.title = `Bolsa de Compras | Seleccionar Dirección | ${store.state.titulo}`;
      } else {
        if (obtenerOrden) {
          if (obtenerOrden.estado === "En Proceso") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/EnProceso.vue");
          } else if (obtenerOrden.estado === "Pagada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Pagada.vue");
          } else if (obtenerOrden.estado === "Enviada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Enviada.vue");
          } else if (obtenerOrden.estado === "Completada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Completada.vue");
          } else if (obtenerOrden.estado === "Cancelada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Cancelada.vue");
          }
          to.meta.title = store.state.titulo;
        } else {
          to.meta.component = () =>
            import(
              "../components/Avisos/Ordenes/Administrador/OrdenEliminada.vue"
            );
          to.meta.title = store.state.titulo;
        }
      }
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  /* BOLSA - DIRECCIONES */
  {
    path: "/bolsa/orden/:id/seleccionardireccion/direcciones",
    name: "BolsaSeleccionarDireccionLista",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerUltimaOrden =
        await store.state.orden.registro.funciones.obtenerUltima(
          auth.currentUser.uid
        );
      let obtenerOrden = await store.state.orden.registro.funciones.obtener(
        to.params.id,
        auth.currentUser.uid
      );
      if (
        obtenerUltimaOrden &&
        obtenerOrden &&
        obtenerUltimaOrden.id === obtenerOrden.id
      ) {
        let obtenerDirecciones =
          await store.state.direccion.funciones.obtenerRegistros(
            auth.currentUser.uid
          );
        to.meta.component = () =>
          obtenerDirecciones.length > 0
            ? import("../views/Bolsa/Direcciones/ListaDirecciones.vue")
            : import("../components/Avisos/Bolsa/Direcciones/SinRegistros.vue");
        to.meta.title =
          obtenerDirecciones.length > 0
            ? `Bolsa de Compras | Seleccionar Dirección | Direcciones | ${store.state.titulo}`
            : store.state.titulo;
      } else {
        if (obtenerOrden) {
          if (obtenerOrden.estado === "En Proceso") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/EnProceso.vue");
          } else if (obtenerOrden.estado === "Pagada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Pagada.vue");
          } else if (obtenerOrden.estado === "Enviada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Enviada.vue");
          } else if (obtenerOrden.estado === "Completada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Completada.vue");
          } else if (obtenerOrden.estado === "Cancelada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Cancelada.vue");
          }
          to.meta.title = store.state.titulo;
        } else {
          to.meta.component = () =>
            import(
              "../components/Avisos/Ordenes/Administrador/OrdenEliminada.vue"
            );
          to.meta.title = store.state.titulo;
        }
      }
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  /* BOLSA - DIRECCIONES - NUEVA DIRECCION */
  {
    path: "/bolsa/orden/:id/seleccionardireccion/direcciones/nuevadireccion",
    name: "BolsaSeleccionarDireccionNueva",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerUltimaOrden =
        await store.state.orden.registro.funciones.obtenerUltima(
          auth.currentUser.uid
        );
      let obtenerOrden = await store.state.orden.registro.funciones.obtener(
        to.params.id,
        auth.currentUser.uid
      );
      if (
        obtenerUltimaOrden &&
        obtenerOrden &&
        obtenerUltimaOrden.id === obtenerOrden.id
      ) {
        let limiteAlcanzado =
          await store.state.direccion.funciones.obtenerLimite(
            auth.currentUser.uid
          );
        to.meta.component = () =>
          limiteAlcanzado
            ? import(
                "../components/Avisos/Bolsa/Direcciones/LimiteRegistros.vue"
              )
            : import("../views/Bolsa/Direcciones/NuevaDireccion.vue");
        to.meta.title = limiteAlcanzado
          ? store.state.titulo
          : `Bolsa de Compras | Seleccionar Dirección | Direcciones | Nueva Dirección | ${store.state.titulo}`;
      } else {
        if (obtenerOrden) {
          if (obtenerOrden.estado === "En Proceso") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/EnProceso.vue");
          } else if (obtenerOrden.estado === "Pagada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Pagada.vue");
          } else if (obtenerOrden.estado === "Enviada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Enviada.vue");
          } else if (obtenerOrden.estado === "Completada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Completada.vue");
          } else if (obtenerOrden.estado === "Cancelada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Cancelada.vue");
          }
          to.meta.title = store.state.titulo;
        } else {
          to.meta.component = () =>
            import(
              "../components/Avisos/Ordenes/Administrador/OrdenEliminada.vue"
            );
          to.meta.title = store.state.titulo;
        }
      }
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  /* BOLSA - DIRECCIONES - EDITAR DIRECCION */
  {
    path: "/bolsa/orden/:idOrden/seleccionardireccion/direcciones/editardireccion/:idDireccion",
    name: "BolsaSeleccionarDireccionEditar",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerUltimaOrden =
        await store.state.orden.registro.funciones.obtenerUltima(
          auth.currentUser.uid
        );
      let obtenerOrden = await store.state.orden.registro.funciones.obtener(
        to.params.idOrden,
        auth.currentUser.uid
      );
      if (
        obtenerUltimaOrden &&
        obtenerOrden &&
        obtenerUltimaOrden.id === obtenerOrden.id
      ) {
        let existeRegistro =
          await store.state.direccion.funciones.validarUsuario(
            to.params.idDireccion,
            auth.currentUser.uid
          );
        to.meta.component = () =>
          !existeRegistro
            ? import(
                "../components/Avisos/Bolsa/Direcciones/DireccionEliminada.vue"
              )
            : import("../views/Bolsa/Direcciones/EditarDireccion.vue");
        to.meta.title = !existeRegistro
          ? store.state.titulo
          : `Bolsa de Compras | Seleccionar Dirección | Direcciones | Editar Dirección | ${store.state.titulo}`;
      } else {
        if (obtenerOrden) {
          if (obtenerOrden.estado === "En Proceso") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/EnProceso.vue");
          } else if (obtenerOrden.estado === "Pagada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Pagada.vue");
          } else if (obtenerOrden.estado === "Enviada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Enviada.vue");
          } else if (obtenerOrden.estado === "Completada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Completada.vue");
          } else if (obtenerOrden.estado === "Cancelada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Cancelada.vue");
          }
          to.meta.title = store.state.titulo;
        } else {
          to.meta.component = () =>
            import(
              "../components/Avisos/Ordenes/Administrador/OrdenEliminada.vue"
            );
          to.meta.title = store.state.titulo;
        }
      }
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  /* BOLSA - CONFIRMAR COMPRA */
  {
    path: "/bolsa/orden/:id/confirmarcompra",
    name: "BolsaConfirmarCompra",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerUltimaOrden =
        await store.state.orden.registro.funciones.obtenerUltima(
          auth.currentUser.uid
        );
      let obtenerOrden = await store.state.orden.registro.funciones.obtener(
        to.params.id,
        auth.currentUser.uid
      );
      if (
        obtenerUltimaOrden &&
        obtenerOrden &&
        obtenerUltimaOrden.id === obtenerOrden.id
      ) {
        to.meta.component = () => import("../views/Bolsa/ConfirmarCompra.vue");
        to.meta.title = `Bolsa de Compras | Confirmar Compra | ${store.state.titulo}`;
      } else {
        if (obtenerOrden) {
          if (obtenerOrden.estado === "En Proceso") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/EnProceso.vue");
          } else if (obtenerOrden.estado === "Pagada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Pagada.vue");
          } else if (obtenerOrden.estado === "Enviada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Enviada.vue");
          } else if (obtenerOrden.estado === "Completada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Completada.vue");
          } else if (obtenerOrden.estado === "Cancelada") {
            to.meta.component = () =>
              import("../components/Avisos/Bolsa/Estados/Cancelada.vue");
          }
          to.meta.title = store.state.titulo;
        } else {
          to.meta.component = () =>
            import(
              "../components/Avisos/Ordenes/Administrador/OrdenEliminada.vue"
            );
          to.meta.title = store.state.titulo;
        }
      }
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
  /* BOLSA - COMPRA CONFIRMADA */
  {
    path: "/bolsa/orden/:id/compraconfirmada",
    name: "BolsaCompraConfirmada",
    component: DynamicWrapper,
    props: (route) => ({ component: route.meta.component }),
    beforeEnter: async (to, from, next) => {
      let obtenerOrden = await store.state.orden.registro.funciones.obtener(
        to.params.id,
        auth.currentUser.uid
      );
      if (obtenerOrden) {
        if (obtenerOrden.estado === "En Proceso") {
          to.meta.component = () =>
            import("../views/Bolsa/CompraConfirmada.vue");
          to.meta.title = `Bolsa de Compras | Compra Confirmada | ${store.state.titulo}`;
        } else if (obtenerOrden.estado === "Pagada") {
          to.meta.component = () =>
            import("../components/Avisos/Bolsa/Estados/Pagada.vue");
          to.meta.title = store.state.titulo;
        } else if (obtenerOrden.estado === "Enviada") {
          to.meta.component = () =>
            import("../components/Avisos/Bolsa/Estados/Enviada.vue");
          to.meta.title = store.state.titulo;
        } else if (obtenerOrden.estado === "Completada") {
          to.meta.component = () =>
            import("../components/Avisos/Bolsa/Estados/Completada.vue");
        } else if (obtenerOrden.estado === "Cancelada") {
          to.meta.component = () =>
            import("../components/Avisos/Bolsa/Estados/Cancelada.vue");
          to.meta.title = store.state.titulo;
        } else {
          to.meta.component = () =>
            import(
              "../components/Avisos/Ordenes/Administrador/OrdenEliminada.vue"
            );
          to.meta.title = store.state.titulo;
        }
      } else {
        to.meta.component = () =>
          import(
            "../components/Avisos/Ordenes/Administrador/OrdenEliminada.vue"
          );
        to.meta.title = store.state.titulo;
      }
      next();
    },
    meta: {
      requiereAuth: true,
      component: null,
      title: null,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {
      x: 0,
      y: 0,
    };
  },
});

router.beforeEach(async (to, from, next) => {
  router.app.$isLoading(true);
  document.body.style.overflowY = "hidden";
  let usuario = auth.currentUser;
  let requiereAuth = to.matched.some((record) => record.meta.requiereAuth);
  // Si requiere autentificacion y tiene usuario
  if (requiereAuth && usuario) {
    next();
  }
  // Si requiere autentificacion y no tiene usuario
  else if (requiereAuth && !usuario) {
    next({ name: "Login" });
  }
  // No requiere autentificacion y tiene usuario
  else if (!requiereAuth && usuario) {
    if (to.name === "Login" || to.name === "Registrarse") {
      next({ name: "Inicio" });
    } else {
      next();
    }
  }
  // No requiere autentificacion y no tiene usuario
  else if (!requiereAuth && !usuario) {
    next();
  }
});

router.afterEach((to) => {
  document.title = to.meta.title ? to.meta.title : store.state.titulo;
  let contenedor = document.getElementById("contenedor");
  setTimeout(() => {
    router.app.$isLoading(false);
    document.body.style.overflowY = "";
  }, 1000);
  setTimeout(() => {
    if (contenedor.querySelector("div")) {
      if (
        document.querySelector(`.${contenedor.querySelector("div").className}`)
      ) {
        document
          .querySelector(`.${contenedor.querySelector("div").className}`)
          .removeAttribute("component");
      }
    }
  }, 300);
});

export default router;
