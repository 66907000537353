<template>
  <div id="app">
    <div id="nav">
      <Navbar />
    </div>
    <div id="contenedor">
      <router-view />
    </div>
    <NotificacionesPush />
    <Footer />
  </div>
</template>

<script>
import Vue from "vue";
import loading from "vuejs-loading-screen";
import Navbar from '@/components/Navbar/Navbar.vue';
import Footer from '@/components/Footer.vue';
import NotificacionesPush from '@/components/NotificacionesPush.vue';
const icon = require("./assets/img/general_logo_domun.webp");
Vue.use(loading, {
  bg: "rgba(255,255,255,1);",
  slot: `
    <div class="d-flex-justify-content-center bg-gray-800 rounded text-center">
      <img src="${icon}" class="img-fluid mb-3" width="300px" height="36px" />
      <span class="loader"></span>
    </div>
  `,
});
export default {
  components: {
    Navbar,
    Footer,
    NotificacionesPush
  }
}
</script>

<style>
* {
  padding: 0;
  margin: 0;
  text-decoration: none;
  box-sizing: border-box;
}

:root {
  --colorPrincipal: #3fa6e1;
  --colorSecundario: #193b79;
  --colorseccion1: #ebebeb;
  --colorseccion2: #EFEFEF;
  --colorTexto: #3b3b3b;
  --colorTexto2: #797979;
  --colorBlanco: #ffffff;
  --colorNegro: #000000;
  --colorRojo: #E6423C;
}

body {
  background-color: var(--colorseccion1) !important;
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-style: normal;
  overflow-y: auto;
}

#app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

#contenedor {
  flex: 1;
}

h1,
h2,
h3,
h4 {
  text-wrap: balance;
}

::-webkit-scrollbar {
  width: 13px;
}

::-webkit-scrollbar-track {
  border-radius: 0px;
  background: #fff;
}

::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 0px;
}

::-webkit-scrollbar-thumb:hover {
  background: gray;
  border-radius: 0px;
}

::-webkit-scrollbar-corner {
  background: transparent;
  border-radius: 0px;
}

.bg_success_changes {
  background-color: rgba(32, 132, 84, 0.2);
}

.loader {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  display: block;
  margin: 15px auto;
  position: relative;
  color: var(--colorSecundario);
  box-sizing: border-box;
  animation: animloader 2s linear infinite;
}

@keyframes animloader {
  0% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }

  25% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
  }

  50% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
  }

  75% {
    box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }

  100% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
}

.form-control {
  outline: none !important;
  border: 1px solid #bbb !important;
  border-radius: 4px;
}

.form-control:focus {
  border: 1px solid var(--colorSecundario) !important;
  box-shadow: none !important;
}

.form-floating label {
  color: var(--colorTexto2) !important;
  font-weight: 500 !important;
}

.form-floating .form-control:focus+label {
  color: var(--colorSecundario) !important;
}

.input-group-text {
  background-color: var(--colorseccion2);
  border: 1px solid #bbb !important;
  color: var(--colorTexto2);
}

.input-group:focus-within .input-group-text {
  border: 1px solid var(--colorSecundario) !important;
  color: var(--colorSecundario) !important
}

input.form-control.border.border-danger::placeholder,
select.form-control.border.border-danger,
input[type="date"].form-control.border.border-danger,
textarea.form-control.border.border-danger::placeholder {
  color: var(--colorRojo) !important;
}

.input-group .input-group-text.border.border-danger {
  border: 1px solid var(--colorRojo) !important;
  color: var(--colorRojo) !important;
}

.form-control.border.border-danger:focus {
  border: 1px solid var(--colorRojo) !important;
  box-shadow: none !important;
}

.form-floating .form-control.border.border-danger+label {
  color: var(--colorRojo) !important;
}

.form-floating input.form-control.border.border-danger::placeholder {
  color: transparent !important;
}

.form-check-input+label {
  color: var(--colorTexto2) !important;
}

.form-check-input:checked {
  background-color: var(--colorSecundario) !important;
  border: 0;
  box-shadow: none;
}

.form-check-input:focus {
  box-shadow: none;
}

.form-check-input {
  border: 1px solid var(--colorSecundario) !important;
  box-shadow: none;
}

.form-check-input:checked+label {
  color: var(--colorSecundario) !important;
  font-weight: bold;
}

.form-floating input.form-control.border.border-danger::placeholder {
  color: transparent !important;
}

.nav-tabs .nav-link {
  color: var(--colorTexto2) !important;
  font-weight: normal;
  background: transparent !important;
  border: none !important;
}

.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  color: var(--colorSecundario) !important;
  font-weight: bold !important;
  border-bottom: 4px solid var(--colorSecundario) !important;
}

.nav-tabs .nav-link.active:hover {
  font-weight: bold !important;
  background-color: transparent !important;
  color: var(--colorSecundario) !important;
  border-bottom: 4px solid var(--colorSecundario) !important;
}

.nav-tabs .nav-link:hover {
  background-color: transparent !important;
  border-bottom: 4px solid #e3e3e3 !important;
  font-weight: 500 !important;
  transition: none !important;
}

.breadcrumb-item+.breadcrumb-item::before {
  color: var(--colorSecundario) !important;
}

.breadcrumb-item a {
  color: var(--colorTexto2) !important;
  font-weight: 400 !important;
  font-size: 12pt;
}

.breadcrumb-item a:hover {
  font-weight: 500 !important;
}

.breadcrumb-item.active a {
  color: var(--colorSecundario) !important;
  font-weight: bold !important;
}

.text-primary {
  color: var(--colorSecundario) !important;
}

.text-danger {
  color: var(--colorRojo) !important;
}

.border-danger {
  border-color: var(--colorRojo) !important;
}

.btn-primary-outline {
  background-color: var(--colorBlanco);
  border: 1px solid var(--colorSecundario);
  color: var(--colorSecundario);
  cursor: pointer;
}

.btn-primary-outline:hover {
  background-color: var(--colorSecundario);
  color: var(--colorBlanco)
}

.btn-primary-outline:hover img {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7482%) hue-rotate(85deg) brightness(102%) contrast(104%);
}

.btn-primary {
  background-color: var(--colorSecundario);
  color: var(--colorBlanco);
  cursor: pointer;
  border: 1px solid transparent;
}

.btn-outline-primary {
  border: 1px solid var(--colorSecundario);
  color: var(--colorSecundario);
}

.btn-outline-secondary {
  color: var(--colorTexto2);
}

.btn-outline-secondary:hover,
.btn-outline-secondary:not(:disabled):not(.disabled).active,
.btn-outline-secondary:not(:disabled):not(.disabled):active {
  background-color: #e3e3e3;
  color: gray;
  border: 1px solid #bbb;
}

.btn-outline-danger {
  border: 1px solid var(--colorRojo);
  color: var(--colorRojo);
}

.btn_cancel {
  background-color: transparent;
  color: var(--colorTexto2);
  border: none;
}

.btn-outline-primary:hover,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.btn-outline-primary:not(:disabled):not(.disabled):active {
  background-color: var(--colorPrincipal);
  color: var(--colorBlanco);
  border: 1px solid transparent;
}

.btn-outline-danger:hover,
.btn-outline-danger:not(:disabled):not(.disabled).active,
.btn-outline-danger:not(:disabled):not(.disabled):active {
  background-color: var(--colorRojo);
  color: var(--colorBlanco);
  border: 1px solid transparent;
}

.btn-primary:hover,
.btn-primary:not(:disabled):not(.disabled).active,
.btn-primary:not(:disabled):not(.disabled):active {
  background-color: var(--colorPrincipal);
  color: var(--colorBlanco);
  border: 1px solid transparent;
}

.btn_cancel:hover,
.btn_cancel:not(:disabled):not(.disabled).active,
.btn_cancel:not(:disabled):not(.disabled):active {
  color: gray;
  font-weight: bold;
}

.btn-primary:disabled {
  background-color: var(--colorSecundario);
  border: 1px solid transparent;
}

.btn_cancel:disabled {
  color: var(--colorTexto2)
}

.cursor_pointer_none {
  pointer-events: none;
}

.grid_app {
  display: grid;
  grid-template-columns: 200px 4fr 200px;
}

.grid_products {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  justify-content: center;
  place-items: center;
  align-items: stretch;
  gap: 10px;
}

.container_profile {
  display: grid;
  grid-template-columns: 280px 4fr;
  background: var(--colorBlanco);
}

.container_loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.badge_state_order {
  font-size: 11pt;
  border-radius: 10px;
}

.badge_state_order.state_danger {
  background: #FFC9A1;
  color: #FF7245;
}

.badge_state_order.state_success {
  background: #DCFCE7;
  color: #82c397;
}

.badge_state_order.state_primary {
  background: #b9e1f8;
  color: var(--colorPrincipal);
}

@media screen and (max-width: 1400px) {

  .grid_app {
    grid-template-columns: 100px 4fr 100px;
  }
}

@media screen and (max-width: 1300px) {

  .grid_app {
    grid-template-columns: 50px 4fr 50px;
  }
}

@media screen and (max-width: 1200px) {

  .grid_app {
    grid-template-columns: 25px 4fr 25px;
  }
}

@media screen and (max-width: 1100px) {
  .grid_bag {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 1000px) {
  .container_profile {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 850px) {

  .grid_app {
    grid-template-columns: 10px 4fr 10px;
  }

  .grid_bag {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 768px) {

  .grid_app {
    grid-template-columns: 1fr;
  }

  .breadcrumb {
    margin-left: 8px;
  }
}

@media screen and (max-width: 2100px) {
  .grid_products {
    grid-template-columns: repeat(auto-fill, minmax(380px, 1fr));
  }
}

@media screen and (max-width: 1960px) {
  .grid_products {
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  }
}

@media screen and (max-width: 1890px) {
  .grid_products {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media screen and (max-width: 1700px) {
  .grid_products {
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  }
}

@media screen and (max-width: 1690px) {
  .grid_products {
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  }
}

@media screen and (max-width: 1450px) {
  .grid_products {
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  }
}

@media screen and (max-width: 1150px) {
  .grid_products {
    grid-template-columns: repeat(auto-fill, minmax(310px, 1fr));
  }
}

@media screen and (max-width: 740px) {
  .grid_products {
    grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  }
}

@media screen and (max-width: 740px) {
  .grid_products {
    grid-template-columns: repeat(auto-fill, minmax(330px, 1fr));
  }
}

@media screen and (max-width: 690px) {
  .grid_products {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media screen and (max-width: 630px) {
  .grid_products {
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  }
}

@media screen and (min-width: 361px) and (max-width: 425px) {
  .breadcrumb-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 80px;
    display: inline-block;
    vertical-align: top;
  }

  .breadcrumb-item.active {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    display: inline-block;
    vertical-align: top;
  }
}

@media screen and (min-width: 281px) and (max-width: 360px) {
  .breadcrumb-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 60px;
    display: inline-block;
    vertical-align: top;
  }

  .breadcrumb-item.active {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 200px;
    display: inline-block;
    vertical-align: top;
  }
}

@media screen and (max-width: 300px) {
  .breadcrumb-item {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 50px;
    display: inline-block;
    vertical-align: top;
  }
}

@media screen and (max-width: 280px) {
  .form-floating label {
    font-size: 10pt;
  }

  .breadcrumb-item.active {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 150px;
    display: inline-block;
    vertical-align: top;
  }
}
</style>
