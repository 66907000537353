/* eslint-disable no-console */
import { register } from "register-service-worker";

// SERVICE WORKER - PROYECTO GENERAL
if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}service-worker.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB"
      );
    },
    registered() {
      console.log("Service worker has been registered.");
    },
    cached() {
      console.log("Content has been cached for offline use.");
    },
    updatefound() {
      console.log("New content is downloading.");
    },
    updated() {
      console.log("New content is available; please refresh.");
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode."
      );
    },
    error(error) {
      console.error("Error during service worker registration:", error);
    },
  });
}

// SERVICE WORKER - FIREBASE MESSAGING
if (process.env.NODE_ENV === "production") {
  register(`${process.env.BASE_URL}firebase-messaging-sw.js`, {
    ready() {
      console.log(
        "App is being served from cache by a service worker.\n" +
          "For more details, visit https://goo.gl/AFskqB (Firebase Messaging)"
      );
    },
    registered() {
      console.log("Service worker has been registered (Firebase Messaging).");
    },
    cached() {
      console.log(
        "Content has been cached for offline use (Firebase Messaging)."
      );
    },
    updatefound() {
      console.log("New content is downloading (Firebase Messaging).");
    },
    updated() {
      console.log(
        "New content is available; please refresh (Firebase Messaging)."
      );
    },
    offline() {
      console.log(
        "No internet connection found. App is running in offline mode (Firebase Messaging)."
      );
    },
    error(error) {
      console.error(
        "Error during service worker registration (Firebase Messaging):",
        error
      );
    },
  });
}
