<template>
    <div class="NavbarCategoriasMarcas">
        <div v-for="(marca, index) of marcas" :key="index">
            <router-link v-if="categoria"
                :to="{ name: 'ProductosCategoriasMarcas', params: { categoria: categoria.conFormato, marca: marca.conFormato } }"
                class="text-decoration-none" :title="marca.sinFormato">
                <div class="d-flex align-items-center item_menu" data-bs-dismiss="offcanvas">
                    <span>{{ marca.sinFormato }}</span>
                </div>
            </router-link>
        </div>
    </div>
</template>

<script>
export default {
    name: "NavbarCategoriasMarcas",

    props: {
        categoria: Object
    },

    data() {
        return {
            marcas: [],
        }
    },

    async created() {
        this.marcas = await Promise.all(
            this.$store.state.marcas.map(async (item) => {
                let url = await this.$store.state.producto.funciones.cambiarNombre(item);
                return {
                    conFormato: url,
                    sinFormato: item
                }
            })
        );
    },
}
</script>

<style scoped>
.item_menu {
    color: var(--colorNegro);
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 25px;
    padding-left: 25px;
    transition: background-color 0.3s ease-in-out;
}

.item_menu img {
    margin-right: 10px;
}

.item_menu:hover {
    background-color: var(--colorPrincipal);
    color: var(--colorBlanco);
    cursor: pointer;
    font-weight: 500;
}

.item_menu:hover img {
    filter: brightness(0) saturate(100%) invert(98%) sepia(1%) saturate(184%) hue-rotate(113deg) brightness(116%) contrast(100%);
}
</style>