var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"FooterDomun"},[_c('footer',{staticClass:"grid_app",class:{ 'fixed-bottom': _vm.ocultarElementos }},[_c('div'),_c('div',[(!_vm.ocultarElementos)?[_vm._m(0),_c('div',{staticClass:"info"},[_vm._m(1),_vm._m(2),_c('div',[_c('p',{staticClass:"title"},[_vm._v("Acerca de")]),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'AcercaDePreguntasFrecuentes' },"title":"Preguntas frecuentes"}},[_c('p',{staticClass:"sub_text"},[_vm._v("Preguntas frecuentes")])]),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'AcercaDeTerminosCondiciones' },"title":"Términos y condiciones"}},[_c('p',{staticClass:"sub_text"},[_vm._v("Términos y condiciones")])]),_c('router-link',{staticClass:"text-decoration-none",attrs:{"to":{ name: 'AcercaDePoliticaCookies' },"title":"Política y configuración de cookies"}},[_c('p',{staticClass:"sub_text"},[_vm._v("Política y configuración de cookies")])])],1),_vm._m(3)])]:_vm._e(),_c('div',{staticClass:"social_media",class:{ 'mb-0 mt-1': _vm.ocultarElementos }},[_c('ul',[_vm._m(4),_vm._m(5),_vm._m(6),_c('li',[_c('span',{attrs:{"title":`Copyright © ${_vm.yearCurrent} domunshop.com`}},[_vm._v("Copyright © "+_vm._s(_vm.yearCurrent)+" domunshop.com")])])])])],2),_c('div')])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"brand"},[_c('img',{staticClass:"img-fluid",attrs:{"src":require("@/assets/img/general_logo_domun.webp"),"alt":"logo","loading":"lazy"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"title"},[_vm._v("Sobre Nosotros")]),_c('p',{staticClass:"sub_text"},[_vm._v(" Somos una empresa dedicada al suministro, venta, instalación y mantenimiento de sistemas de aire acondicionado, residencial, comercial e industrial, con el objetivo de satisfacerlas necesidades actuales y futuras de nuestros clientes. ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('p',{staticClass:"title"},[_vm._v("Contáctenos")]),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"mailto:comercial@domunsas.com","title":"comercial@domunsas.com"}},[_c('p',{staticClass:"sub_text"},[_vm._v("comercial@domunsas.com")])]),_c('a',{staticClass:"text-decoration-none",attrs:{"href":"tel:+573223019638","title":"+57 3223019638"}},[_c('p',{staticClass:"sub_text"},[_vm._v("+57 3223019638")])]),_c('p',{staticClass:"sub_text",attrs:{"title":"Carrera 10 No. 7 - 28 Tauramena, Casanare."}},[_vm._v("Carrera 10 No. 7 - 28 Tauramena, Casanare.")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"location"},[_c('p',{staticClass:"title"},[_vm._v("¿Cómo encontrarnos?")]),_c('div',{staticClass:"ratio ratio-16x9 mb-3",attrs:{"title":"¿Cómo encontrarnos?"}},[_c('iframe',{attrs:{"src":"https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d702.6051513699945!2d-72.74754039962563!3d5.016601058716539!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8e6ad848db06fc0d%3A0xacdc204e934f68f5!2sCra.%2010%20%237-28%2C%20Tauramena%2C%20Casanare!5e0!3m2!1ses!2sco!4v1714168428881!5m2!1ses!2sco","allowfullscreen":"","loading":"lazy","referrerpolicy":"no-referrer-when-downgrade"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://www.instagram.com/domunsoluciones?igsh=aGJsMGRpMjJpZnph","target":"_blank","title":"Instagram"}},[_c('li',[_c('img',{attrs:{"src":require("@/assets/img/footer_icono_instagram.webp"),"width":"16px","height":"16px","alt":"icono-instagram","loading":"lazy"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://www.facebook.com/profile.php?id=100089628498632&mibextid=ZbWKwL","target":"_blank","title":"Facebook"}},[_c('li',[_c('img',{attrs:{"src":require("@/assets/img/footer_icono_facebook.webp"),"width":"8px","height":"16px","alt":"icono-facebook","loading":"lazy"}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',{attrs:{"href":"https://wa.me/573024906983","target":"_blank","title":"WhatsApp"}},[_c('li',[_c('img',{attrs:{"src":require("@/assets/img/footer_icono_whatsapp.webp"),"width":"15px","height":"15px","alt":"icono-whatsapp","loading":"lazy"}})])])
}]

export { render, staticRenderFns }