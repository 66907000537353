<template>
    <div class="BuscadorNavbar">
        <div class="d-flex align-items-center gap-1">
            <!-- MOSTRAR U BUSCADOR MOVIL -->
            <input type="checkbox" :id="'toggle-search-' + indice" v-model="alternar" class="toggle_search_checkbox" />
            <label :for="'toggle-search-' + indice" class="toggle_search_label" title="Buscar Productos">
                <span>
                    <svg viewBox="0 0 24 24" height="24" width="24" xmlns="http://www.w3.org/2000/svg"
                        stroke-width="1.5" class="guWAyx icon_search"
                        style="max-width: 24px; min-width: 24px; height: auto;">
                        <path d="M0.750 9.812 A9.063 9.063 0 1 0 18.876 9.812 A9.063 9.063 0 1 0 0.750 9.812 Z"
                            fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round"
                            transform="translate(-3.056 4.62) rotate(-23.025)"></path>search_item_image
                        <path d="M16.221 16.22L23.25 23.25" fill="none" stroke="currentColor" stroke-linecap="round"
                            stroke-linejoin="round"></path>
                    </svg>
                </span>
                <span>
                    <i class="fas fa-chevron-left hide_search"></i>
                </span>
            </label>
            <!-- BUSCADOR -->
            <div class="search_container">
                <div class="search_bar">
                    <div class="box">
                        <form @submit.prevent="irResultados" autocomplete="off" class="w-100">
                            <input type="text" ref="inputBuscador" v-model="buscador"
                                placeholder="¿Qué producto estás buscando?" @input="tiempoBusqueda" />
                        </form>
                    </div>
                    <div class="btn_search" @click="buscarResultados(buscador)">
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                            <path
                                d="M15.5 14h-.79l-.28-.27A6.471 6.471 0 0 0 16 9.5 6.5 6.5 0 1 0 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" />
                            <path d="M0 0h24v24H0z" fill="none" />
                        </svg>
                    </div>
                </div>
                <div v-if="cargando" class="search_results">
                    <BarraCarga class="mt-3 mb-3" />
                </div>
                <template v-else>
                    <div v-if="resultados.length > 0" class="search_results">
                        <div v-for="(producto, index) of resultados" :key="index" @click="ocultarElementos()">
                            <router-link
                                :to="{ name: 'ProductosVerProducto', params: { nombre: producto.url, id: producto.id } }"
                                class="search_item text-decoration-none">
                                <img :src="producto.imagen ? producto.imagen : $store.state.imagenes.defecto.producto"
                                    alt="" class="search_item_image border">
                                <div class="search_item_details">
                                    <p class="search_item_name fw-bold">{{ producto.nombre }}</p>
                                    <p class="search_item_name fw-semibold">{{ producto.categoria }} / {{ producto.marca
                                        }}</p>
                                    <p class="search_item_price">{{ producto.precio }}</p>
                                </div>
                            </router-link>
                        </div>
                    </div>
                    <div v-if="resultados.length > 0 && (cantidad > resultados.length)"
                        class="search_results_more border p-2">
                        <router-link :to="{ name: 'ProductosResultadosBusqueda', params: { busqueda: busqueda } }"
                            class="text-center fw-semibold text-decoration-none text-dark w-100">
                            Mostrar todos los resultados
                        </router-link>
                    </div>
                </template>
            </div>
        </div>
        <div :id="'overlay-' + indice" class="overlay"></div>
    </div>
</template>

<script>
import { functions } from '@/firebase';
import { httpsCallable } from "firebase/functions";
import BarraCarga from "@/components/Generales/BarraCarga.vue";
export default {
    name: "BuscadorNavbar",

    components: {
        BarraCarga
    },

    props: {
        indice: {
            type: Number,
            default: 0
        }
    },

    data() {
        return {
            alternar: false,
            resultados: [],
            cantidad: 0,
            buscador: "",
            cargando: false,
            temporizador: null,
            busqueda: "",
        };
    },

    watch: {
        async buscador() {
            if (!this.buscador) {
                this.resultados = [];
                this.cantidad = 0;
                this.cargando = false;
                this.busqueda = "";
                document.body.style.overflowY = "";
            } else {
                this.busqueda = await this.$store.state.modificarBusqueda(this.buscador);
                document.body.style.overflowY = "hidden";
                document.getElementById('overlay-' + this.indice).style.display = "block";
            }
        },

        alternar() {
            this.ocultarIconos(this.alternar);
            if (!this.alternar) {
                this.buscador = "";
                document.getElementById('overlay-' + this.indice).style.display = "none";
                document.body.style.overflowY = "";
            } else {
                document.getElementById('overlay-' + this.indice).style.display = "block";
                document.getElementById('overlay-' + this.indice).style.top = "68px";
                document.body.style.overflowY = "hidden";
            }
        },

        $route(to) {
            this.buscador = "";
            if (window.innerWidth <= 768) {
                this.alternar = false;
            }
            document.getElementById('overlay-' + this.indice).style.display = "none";
        },
    },

    mounted() {
        if (document.getElementById("overlay-" + this.indice)) {
            document.getElementById("overlay-" + this.indice).addEventListener("click", () => {
                if (window.innerWidth <= 768) {
                    this.alternar = false;
                } else {
                    document.body.style.overflowY = "";
                    document.getElementById('overlay-' + this.indice).style.display = "none";
                    this.buscador = "";
                }
            });
        }
    },

    methods: {
        ocultarIconos(checked) {
            if (window.innerWidth <= 768) {
                const header = document.getElementById("header_sm");
                const elements = ["brand-navbar", "favorites-navbar", "buys-navbar", "user-navbar", "notifications-navbar"];
                if (checked) {
                    header.classList.remove('justify-content-between');
                    header.classList.add('justify-content-center');
                    elements.forEach(id => document.getElementById(id).classList.add("d-none"));
                } else {
                    header.classList.add('justify-content-between');
                    header.classList.remove('justify-content-center');
                    elements.forEach(id => document.getElementById(id).classList.remove("d-none"));
                }
            }
        },

        tiempoBusqueda() {
            if (this.buscador) {
                clearTimeout(this.temporizador);
                this.temporizador = setTimeout(() => {
                    this.cargando = true;
                    this.buscar();
                }, 500);
            } else {
                this.resultados = [];
                this.cantidad = 0;
                this.cargando = false;
            }
        },

        async buscar() {
            const buscarProductos = await httpsCallable(functions, 'buscarProductos');
            await buscarProductos({ textoBusqueda: this.buscador }).then((result) => {
                this.resultados = result.data.productos;
                this.cantidad = result.data.cantidad;
                this.cargando = false;
            }).catch(() => {
                this.cargando = false;
            });
        },

        ocultarElementos() {
            if (window.innerWidth <= 768) {
                this.alternar = false;
            }
            this.buscador = "";
        },

        async buscarResultados(busqueda) {
            if (busqueda) {
                const irRuta = `/productos/busqueda/${this.busqueda}`;
                if (this.$route.path !== irRuta) {
                    this.$router.push(irRuta).catch(err => {
                        if (err.name !== 'NavigationDuplicated') {
                            throw err;
                        }
                    });
                }
            }
            this.$refs.inputBuscador.blur();
        },

        irResultados() {
            clearTimeout(this.temporizador);
            this.resultados = [];
            this.cantidad = 0;
            this.cargando = false;
            this.buscarResultados(this.buscador);
        }
    }
}
</script>

<style scoped>
.search_container {
    position: relative;
}

.search_bar {
    display: flex;
    width: 390px;
    margin-right: 10px;
    margin-left: 10px;
    align-items: center;
}

.search_bar .box {
    background-color: #efefef;
    width: 364px;
    height: 36px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    z-index: 900;
}

.search_bar .box input {
    width: 100%;
    border: none;
    outline: none;
    background: none;
}

.search_bar .btn_search {
    background-color: #2c6484;
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 900;
}

.search_bar svg {
    fill: white;
}

svg,
i {
    filter: brightness(0) saturate(100%) invert(98%) sepia(1%) saturate(184%) hue-rotate(113deg) brightness(116%) contrast(100%);
    cursor: pointer;
}

i {
    font-size: 15pt;
    margin-left: 10px;
}

.search_results {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow-y: auto;
    z-index: 900;
}

.search_results_more {
    position: absolute;
    top: 335px;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #fff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    height: 40px;
    z-index: 900;
    display: flex;
    align-items: center;
    justify-content: center;
}

.search_item {
    display: flex;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;
    transition: background 0.3s ease;
    gap: 10px;
}

.search_results_more:hover,
.search_item:hover {
    background-color: #F3F3F3;
    cursor: pointer;
}

.search_item_image {
    position: relative;
    width: 100%;
    max-width: 60px;
    height: 70px;
    background-size: cover;
    background-position: center;
    border-radius: 4px;
    background-color: var(--colorseccion2);
}

.search_item_details {
    flex: 1;
}

p {
    margin-bottom: 0px;
}

.search_item_name {
    font-size: 16px;
    color: #333;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.search_item_price {
    font-size: 14px;
    color: #999;
    font-weight: 600;
}

input[type="checkbox"],
.icon_search,
.hide_search {
    display: none !important;
}

.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    z-index: 800;
}

@media screen and (max-width: 900px) {
    .search_bar {
        width: 335px;
    }

    .search_bar .box {
        width: 335px;
    }
}

@media screen and (max-width: 768px) {
    .search_container {
        display: none;
    }

    .search_bar .box {
        font-size: 11pt;
    }

    .search_results {
        position: fixed;
        top: 68px;
        left: 0;
        right: 0;
        max-width: 100%;
        z-index: 900;
    }

    .search_results_more {
        position: fixed;
        top: 365px;
        left: 0;
        right: 0;
        max-width: 100%;
        z-index: 900;
    }

    .search_results_more a {
        font-size: 11pt;
    }

    .search_item_name {
        font-size: 14px;
    }

    .search_item_price {
        font-size: 12px;
    }

    .toggle_search_label {
        cursor: pointer;
    }

    .overlay {
        display: none;
        position: fixed;
        top: 106px;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 800;
    }

    p {
        font-size: 11pt;
    }

    .icon_search {
        display: block !important;
    }

    .toggle_search_checkbox:checked~.search_container {
        display: block !important;
    }

    .toggle_search_checkbox:checked~.toggle_search_label .icon_search {
        display: none !important;
    }

    .toggle_search_checkbox:checked~.toggle_search_label .hide_search {
        display: block !important;
    }
}

@media screen and (max-width: 576px) {
    .search_item_name {
        font-size: 13px;
    }

    .search_item_price {
        font-size: 11px;
    }
}

@media screen and (min-width: 745px) and (max-width: 768px) {
    .search_bar {
        width: 700px;
    }

    .search_bar .box {
        width: 700px;
    }
}

@media screen and (min-width: 700px) and (max-width: 745px) {
    .search_bar {
        width: 650px;
    }

    .search_bar .box {
        width: 650px;
    }
}

@media screen and (min-width: 653px) and (max-width: 700px) {
    .search_bar {
        width: 600px;
    }

    .search_bar .box {
        width: 600px;
    }
}

@media screen and (min-width: 600px) and (max-width: 652px) {
    .search_bar {
        width: 550px;
    }

    .search_bar .box {
        width: 550px;
    }
}

@media screen and (min-width: 550px) and (max-width: 600px) {
    .search_bar {
        width: 500px;
    }

    .search_bar .box {
        width: 500px;
    }
}

@media screen and (min-width: 500px) and (max-width: 550px) {
    .search_bar {
        width: 450px;
    }

    .search_bar .box {
        width: 450px;
    }
}

@media screen and (min-width: 450px) and (max-width: 500px) {
    .search_bar {
        width: 400px;
    }

    .search_bar .box {
        width: 400px;
    }
}

@media screen and (min-width: 400px) and (max-width: 450px) {
    .search_bar {
        width: 350px;
    }

    .search_bar .box {
        width: 350px;
    }
}

@media screen and (min-width: 350px) and (max-width: 400px) {
    .search_bar {
        width: 300px;
    }

    .search_bar .box {
        width: 300px;
    }
}

@media screen and (min-width: 300px) and (max-width: 350px) {
    .search_bar {
        width: 250px;
    }

    .search_bar .box {
        width: 250px;
        font-size: 10pt;
    }
}

@media screen and (min-width: 250px) and (max-width: 300px) {
    .search_bar {
        width: 230px;
    }

    .search_bar .box {
        width: 230px;
        font-size: 9pt;
    }
}

@media screen and (max-width: 250px) {

    .toggle_search_checkbox,
    .toggle_search_label .icon_search,
    .toggle_search_label .hide_search,
    .search_container {
        display: none !important;
    }
}
</style>