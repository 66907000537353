<template>
    <div class="DomunNotificacionesPush">
        <div class="overlay" id="overlay-notification"></div>
        <!-- PREGUNTAR PERMISO -->
        <div class="toast-container position-fixed top-50 start-50 translate-middle p-3">
            <div id="preguntarPermiso" class="toast" role="alert" aria-live="assertive" aria-atomic="true"
                data-bs-autohide="false">
                <div class="toast-header">
                    <strong class="me-auto">Aviso Importante</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"
                        title="Cerrar"></button>
                </div>
                <div class="toast-body bg-light">
                    <p class="fw-normal">¿Deseas recibir notificaciones push de tus <b>órdenes de compra?</b></p>
                    <div class="mt-2 pt-2 border-top">
                        <button type="button" class="btn btn_cancel btn-sm ms-1" data-bs-dismiss="toast"
                            title="Cancelar">
                            Cancelar
                        </button>
                        <button type="button" class="btn btn-primary btn-sm" @click="activarPermiso()" title="Aceptar">
                            Aceptar
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <!-- MOSTRAR INFORMACIÓN -->
        <div class="toast-container position-fixed top-50 start-50 translate-middle p-3">
            <div id="informacionImportante" class="toast" role="alert" aria-live="assertive" aria-atomic="true"
                data-bs-autohide="false">
                <div class="toast-header">
                    <strong class="me-auto">Información Importante</strong>
                    <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"
                        title="Cerrar"></button>
                </div>
                <div class="toast-body bg-light">
                    <p class="fw-normal">
                        No has aceptado recibir notificaciones push.<br /><br />
                        No te preocupes, si deseas activarlas, puedes
                        hacerlo en el apartado de <b>Configuración</b> habilitando la opción
                        <b>Notificaciones Push</b>.
                    </p>
                    <div class="mt-2 pt-2 border-top text-end">
                        <button type="button" class="btn btn-primary btn-sm" data-bs-dismiss="toast" title="Entendido">
                            Entendido
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { auth } from "@/firebase";
import { Toast } from "bootstrap";
export default {
    name: "DomunNotificacionesPush",

    data() {
        return {
            existeToken: null,
            esPermitido: false
        }
    },

    mounted() {
        document.getElementById('preguntarPermiso').addEventListener('shown.bs.toast', () => {
            document.getElementById('overlay-notification').style.display = 'block';
            document.body.style.overflowY = "hidden";
        });
        document.getElementById('preguntarPermiso').addEventListener('hidden.bs.toast', async () => {
            if (this.esPermitido) {
                document.getElementById('overlay-notification').style.display = 'none';
                document.body.style.overflowY = "";
            } else {
                this.mostrarToastInfo();
                if (this.existeToken) {
                    await this.$store.state.notificacionToken.funciones.eliminarBD(this.existeToken.id);
                }
                await this.$store.state.usuario.funciones.alternarNotificaciones(auth.currentUser.uid, false, 'Denegado');
            }
        });
        if (document.getElementById('overlay-notification')) {
            document.getElementById('overlay-notification').addEventListener('click', () => {
                this.cerrarToastPreguntar();
                if (document.getElementById('informacionImportante')) {
                    this.cerrarToastInfo();
                }
            });
        }
        if (document.getElementById('informacionImportante')) {
            document.getElementById('informacionImportante').addEventListener('hidden.bs.toast', () => {
                document.getElementById('overlay-notification').style.display = 'none';
                document.body.style.overflowY = "";
            });
        }
    },

    async created() {
        let usuario = auth.currentUser;
        if (usuario) {
            let usuarioDB = await this.$store.state.usuario.funciones.obtener(usuario.uid);
            if (usuarioDB) {
                if (usuarioDB.preferencias.notificacionesPush.estado === 'Pendiente' || usuarioDB.preferencias.notificacionesPush.estado === 'Permitido') {
                    this.validarDispositivo();
                }
            }
        }
    },

    methods: {
        async crearToken(token) {
            let nuevoToken = new this.$store.state.notificacionToken.objeto;
            nuevoToken.id = await this.$store.state.notificacionToken.funciones.generarID();
            nuevoToken.token = token;
            nuevoToken.registro.idUsuario = auth.currentUser.uid;
            return await this.$store.state.notificacionToken.funciones.crear(nuevoToken);
        },

        async verificarToken() {
            let existeToken = await this.$store.state.notificacionToken.funciones.validar(auth.currentUser.uid);
            if (!existeToken) {
                await this.validarToken();
            }
        },

        async validarToken() {
            try {
                const tokenActual = await this.$store.state.notificacionToken.funciones.obtener();
                if (tokenActual) {
                    const tokenGuardado = await this.crearToken(tokenActual);
                    await this.$store.state.usuario.funciones.alternarNotificaciones(auth.currentUser.uid, true, 'Permitido');
                    if (!tokenGuardado) {
                        await this.$store.state.notificacionToken.funciones.eliminarFCM(tokenActual);
                    }
                }
            } catch (error) {
                if (error.code === "messaging/permission-blocked") {
                    console.error("Las notificaciones están bloqueadas. Por favor, habilítalas desde la configuración del navegador.");
                } else if (error.code === "messaging/permission-default") {
                    console.error("El usuario no aceptó ni rechazó las notificaciones.");
                } else if (error.code === "messaging/token-unsubscribe-failed") {
                    console.error("Error al desuscribirse del token FCM:", error.message);
                } else {
                    console.error("Error desconocido al obtener el token FCM:", error);
                }
            }
        },

        activarPermiso() {
            if ('Notification' in window) {
                Notification.requestPermission().then(async (permission) => {
                    if (permission === 'granted') {
                        this.esPermitido = true;
                        this.cerrarToastPreguntar();
                        this.$store.state.alertaToast("success", "Notificaciones push activadas correctamente");
                        if (this.existeToken) {
                            await this.$store.state.notificacionToken.funciones.eliminarBD(this.existeToken.id);
                            await this.validarToken();
                        } else {
                            await this.validarToken();
                        }
                        await this.$store.state.usuario.funciones.alternarNotificaciones(auth.currentUser.uid, true, 'Permitido');
                    } else if (permission === 'denied') {
                        this.esPermitido = false;
                        this.cerrarToastPreguntar();
                    } else {
                        this.cerrarToastPreguntar();
                    }
                }).catch(() => {
                    this.cerrarToastPreguntar();
                });
            } else {
                this.cerrarToastPreguntar();
            }
        },

        cerrarToastPreguntar() {
            const toastPreguntarPermiso = document.getElementById('preguntarPermiso');
            const toast = new Toast(toastPreguntarPermiso);
            toast.hide();
        },

        cerrarToastInfo() {
            const toastInfo = document.getElementById('informacionImportante');
            const toast = new Toast(toastInfo);
            toast.hide();
        },

        mostrarToastInfo() {
            const toastInfo = document.getElementById('informacionImportante');
            const toast = new Toast(toastInfo);
            toast.show();
        },

        async validarDispositivo() {
            let obtenerDispositivo = await this.$store.state.detectarDispositivo();
            if (obtenerDispositivo) {
                const permission = Notification.permission;
                if (permission === 'default') {
                    this.existeToken = await this.$store.state.notificacionToken.funciones.validar(auth.currentUser.uid);
                    setTimeout(() => {
                        const toastPreguntarPermiso = document.getElementById('preguntarPermiso');
                        const toast = new Toast(toastPreguntarPermiso);
                        toast.show();
                    }, 1000);
                } else if (permission === 'granted') {
                    this.verificarToken();
                } else {
                    this.cerrarToastPreguntar();
                }
            }
        }
    }
}
</script>

<style scoped>
.overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1050;
}

.toast-container {
    z-index: 1051;
}
</style>