<template>
    <div class="UsuarioAcciones">
        <div v-if="cargando && esMovil">
            <BarraCarga />
        </div>
        <template v-else>
            <div v-if="usuario.id">
                <router-link :to="{ name: 'PerfilInfoPersonal' }" class="text-decoration-none"
                    title="Cuenta de Usuario">
                    <div class="item_menu_user" @click="cerrarOffcanvas()">
                        <img :src="usuario.foto ? usuario.foto : require('@/assets/img/general_foto_perfil_defecto.webp')"
                            width="40px" alt="foto-perfil" loading="lazy" />
                        <div class="d-flex flex-column text_menu_user">
                            <p class="fw-bold text_menu_user">{{ usuario.nombre }}</p>
                            <p class="fw-light text_menu_user">{{ usuario.correo }}</p>
                        </div>
                    </div>
                </router-link>
                <router-link v-if="usuario.rol === 'Administrador'" :to="{ name: 'PanelAdministrativoOrdenes' }"
                    class="text-decoration-none" title="Panel Administrativo">
                    <div class="d-flex align-items-center item_menu" @click="cerrarOffcanvas()">
                        <img class="me-2" src="@/assets/img/navbar_icono_panel_administrativo.webp" alt="icono-panel"
                            loading="lazy" />
                        <span>Panel Administrativo</span>
                    </div>
                </router-link>
                <router-link :to="{ name: 'PerfilNotificaciones' }" class="text-decoration-none" title="Notificaciones">
                    <div class="d-flex align-items-center item_menu_2" @click="cerrarOffcanvas()">
                        <img class="me-2 filter_blue" src="@/assets/img/general_icono_notificaciones_relleno.webp"
                            alt="icono-notificaciones" loading="lazy" />
                        <span>Notificaciones</span>
                    </div>
                </router-link>
                <router-link :to="{ name: 'PerfilFavoritos' }" class="text-decoration-none" title="Favoritos">
                    <div class="d-flex align-items-center item_menu" @click="cerrarOffcanvas()">
                        <img class="me-2 filter_blue" src="@/assets/img/general_icono_favoritos_relleno.webp"
                            alt="icono-favoritos" loading="lazy" />
                        <span>Favoritos</span>
                    </div>
                </router-link>
                <router-link :to="{ name: 'PerfilOrdenes' }" class="text-decoration-none" title="Órdenes">
                    <div class="d-flex align-items-center item_menu" @click="cerrarOffcanvas()">
                        <img class="me-2" src="@/assets/img/navbar_icono_ordenes.webp" alt="icono-ordenes"
                            loading="lazy" />
                        <span>Órdenes</span>
                    </div>
                </router-link>
                <router-link :to="{ name: 'PerfilDirecciones' }" class="text-decoration-none" title="Direcciones">
                    <div class="d-flex align-items-center item_menu" @click="cerrarOffcanvas()">
                        <img class="me-2" src="@/assets/img/navbar_icono_direcciones.webp" alt="icono-direcciones"
                            loading="lazy" />
                        <span>Direcciones</span>
                    </div>
                </router-link>
                <router-link v-if="esMovil" :to="{ name: 'PerfilConfiguracion' }" class="text-decoration-none"
                    title="Configuración">
                    <div class="d-flex align-items-center item_menu" @click="cerrarOffcanvas()">
                        <img class="me-2" src="@/assets/img/navbar_icono_configuracion.webp" alt="icono-contrasena"
                            loading="lazy" />
                        <span>Configuración</span>
                    </div>
                </router-link>
                <router-link :to="{ name: 'PerfilCambiarPassword' }" class="text-decoration-none"
                    title="Cambiar Contraseña">
                    <div class="d-flex align-items-center item_menu" @click="cerrarOffcanvas()">
                        <img class="me-2" src="@/assets/img/navbar_icono_contrasena.webp" alt="icono-contrasena"
                            loading="lazy" />
                        <span>Cambiar Contraseña</span>
                    </div>
                </router-link>
                <div class="d-flex align-items-center item_menu" @click="cerrarSesion(); cerrarOffcanvas();"
                    title="Salir">
                    <img class="me-2" src="@/assets/img/navbar_icono_salir.webp" alt="icono-salir" loading="lazy" />
                    <span>Salir</span>
                </div>
            </div>
            <div v-else>
                <router-link :to="{ name: 'Login' }" class="text-decoration-none" @click="cerrarOffcanvas()"
                    title="Iniciar Sesión">
                    <div class="d-flex align-items-center item_menu">
                        <img class="me-2" src="@/assets/img/navbar_icono_iniciar_sesion.webp" alt="icono-iniciar"
                            loading="lazy" />
                        <span>Iniciar Sesión</span>
                    </div>
                </router-link>
                <router-link :to="{ name: 'Registrarse' }" class="text-decoration-none" @click="cerrarOffcanvas()"
                    title="Registrarse">
                    <div class="d-flex align-items-center item_menu">
                        <img class="me-2" src="@/assets/img/navbar_icono_registrarse.webp" alt="icono-registrarse"
                            loading="lazy" />
                        <span>Registrarse</span>
                    </div>
                </router-link>
            </div>
        </template>
    </div>
</template>

<script>
import { auth, db } from '@/firebase';
import { signOut } from "firebase/auth";
import {
    doc,
    onSnapshot
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import Swal from 'sweetalert2';
import { Offcanvas } from 'bootstrap';
import BarraCarga from "../../../Generales/BarraCarga.vue";
export default {
    name: "UsuarioAcciones",

    components: {
        BarraCarga
    },

    data() {
        return {
            usuario: {
                id: "",
                rol: "",
                foto: "",
                nombre: "",
                correo: "",
            },
            esMovil: false,
            cargando: false,
        }
    },

    watch: {
        cargando() {
            this.$emit("cargando", this.cargando)
        }
    },

    async created() {
        this.esMovil = await this.$store.state.detectarDispositivo();
        this.cargando = this.esMovil ? true : false;
        onAuthStateChanged(auth, async () => {
            let usuario = auth.currentUser;
            if (usuario) {
                let usuarioDB = await this.$store.state.usuario.funciones.obtener(usuario.uid);
                if (usuarioDB) {
                    await this.obtenerUsuario(usuarioDB.id);
                }
            }
        });
        if (this.esMovil) {
            setTimeout(() => {
                this.cargando = false;
            }, 1000);
        }
    },

    methods: {
        async obtenerUsuario(id) {
            if (id) {
                onSnapshot(doc(db, "usuarios", id), async (doc) => {
                    this.usuario.id = doc.id ? doc.id : "";
                    this.usuario.rol = doc.data() ? doc.data().rol : "";
                    this.usuario.foto = doc.data() ? doc.data().foto : "";
                    this.usuario.nombre = doc.data() ? doc.data().nombre + " " + doc.data().apellido : "";
                    this.usuario.correo = doc.data() ? doc.data().correo : "";
                });
            }
        },

        cerrarOffcanvas() {
            if (window.innerWidth < 768) {
                Offcanvas.getInstance(document.getElementById(`offcanvasActions`)).hide();
            }
        },

        cerrarSesion() {
            Swal.fire({
                icon: "info",
                title: "<h5>¿Deseas cerrar la sesión?</h5>",
                background: "rgba(255, 255, 255)",
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: "Cerrar Sesión",
                cancelButtonText: "Cancelar",
                reverseButtons: true,
                customClass: {
                    confirmButton: "btn btn-primary btn-md fw-bold me-2 mb-3",
                    cancelButton: "btn btn_cancel btn-md fw-bold mb-3",
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
            }).then(async (result) => {
                if (result.value) {
                    this.$isLoading(true);
                    signOut(auth).then(() => {
                        this.$router.go(0);
                    }).catch((error) => {
                        this.$isLoading(false);
                        this.$store.state.alertaToast("error", "No se ha cerrado sesión. Inténtalo de nuevo.");
                    });
                }
            });
        }
    }
}
</script>

<style scoped>
.item_menu,
.item_menu_2 {
    font-size: 11.5pt;
    padding: 8px;
    color: var(--colorNegro);
}

.item_menu_user {
    display: flex;
    align-items: center;
    font-size: 11.5pt;
    padding: 10px;
    gap: 8px;
    color: var(--colorNegro);
    background-color: #f3f3f3;
}

.item_menu_user img {
    border-radius: 25px;
}

.text_menu_user {
    margin-bottom: 0px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
}

.item_menu img {
    width: 20px;
    margin-left: 10px;
}

.item_menu_2 img {
    width: 23px;
    margin-left: 8.5px;
}

.item_menu:hover,
.item_menu_2:hover,
.item_menu_user:hover {
    background-color: var(--colorPrincipal);
    color: var(--colorBlanco);
    cursor: pointer;
    font-weight: 500;
}

.item_menu:hover img,
.item_menu_2:hover img {
    filter: brightness(0) saturate(100%) invert(98%) sepia(1%) saturate(184%) hue-rotate(113deg) brightness(116%) contrast(100%);
}

.filter_blue {
    filter: brightness(0) saturate(100%) invert(16%) sepia(75%) saturate(1370%) hue-rotate(199deg) brightness(99%) contrast(95%);
}

@media (max-width: 768px) {

    .item_menu,
    .item_menu_2 {
        color: var(--colorNegro);
        padding-top: 15px;
        padding-bottom: 15px;
        padding-right: 25px;
        padding-left: 25px;
        transition: background-color 0.3s ease-in-out;
    }

    .item_menu_user {
        padding-right: 25px;
        padding-left: 22px;
        transition: background-color 0.3s ease-in-out;
    }

    .item_menu img {
        width: 28px;
        margin-left: 0px;
        filter: brightness(0) saturate(100%) invert(52%) sepia(0%) saturate(0%) hue-rotate(187deg) brightness(90%) contrast(88%);
    }

    .item_menu_2 img {
        width: 30px;
        margin-left: -2px;
        filter: brightness(0) saturate(100%) invert(52%) sepia(0%) saturate(0%) hue-rotate(187deg) brightness(90%) contrast(88%);
    }
}
</style>