import Vue from "vue";
import Vuex from "vuex";
import { db, messaging } from "@/firebase";
import { getToken, deleteToken } from "firebase/messaging";
import {
  serverTimestamp,
  query,
  collection,
  where,
  doc,
  setDoc,
  updateDoc,
  deleteDoc,
  getDocs,
  getDoc,
  orderBy,
  limit,
} from "firebase/firestore";
import VueToast from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import SimpleCrypto from "simple-crypto-js";
import Swal from "sweetalert2";
Vue.use(VueToast);
Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    imagenes: {
      logo: require("@/assets/img/general_logo_domun.webp"),
      defecto: {
        producto: require("@/assets/img/general_imagen_producto_defecto.webp"),
        carrusel: [
          {
            id: 0,
            url: require("@/assets/img/general_carrusel_imagen_1.webp"),
            cargando: false,
          },
          {
            id: 1,
            url: require("@/assets/img/general_carrusel_imagen_2.webp"),
            cargando: false,
          },
          {
            id: 2,
            url: require("@/assets/img/general_carrusel_imagen_3.webp"),
            cargando: false,
          },
          {
            id: 3,
            url: require("@/assets/img/general_carrusel_imagen_4.webp"),
            cargando: false,
          },
          {
            id: 4,
            url: require("@/assets/img/general_carrusel_imagen_5.webp"),
            cargando: false,
          },
          {
            id: 5,
            url: require("@/assets/img/general_carrusel_imagen_6.webp"),
            cargando: false,
          },
        ],
      },
    },
    usuario: {
      objeto: class {
        constructor() {
          return {
            id: "",
            nombre: "",
            apellido: "",
            documento: {
              numero: "",
              tipo: "",
            },
            foto: "",
            correo: "",
            password: "",
            preferencias: {
              idDireccionSeleccionada: "",
              terminosCondiciones: false,
              politicaPrivacidad: false,
              notificacionesPush: {
                estado: "Pendiente",
                esAceptada: false,
              },
            },
            telefono: 0,
            rol: "Usuario",
            registro: {
              tipo: "Usuario",
              estado: "Creado",
              metodo: "",
              creacion: serverTimestamp(),
              actualizacion: serverTimestamp(),
            },
          };
        }
      },
      funciones: {
        async crear(usuario) {
          await setDoc(doc(db, "usuarios", usuario.id), usuario);
        },
        async actualizar(usuario) {
          await updateDoc(doc(db, "usuarios", usuario.id), usuario);
        },
        async obtener(id) {
          const q = query(
            collection(db, "usuarios"),
            where("id", "==", id),
            where("registro.estado", "in", ["Creado", "Editado"]),
            limit(1)
          );
          const querySnapshot = await getDocs(q);
          return querySnapshot.docs.length > 0
            ? querySnapshot.docs[0].data()
            : null;
        },
        async seleccionarDireccion(idUsuario, id) {
          await updateDoc(doc(db, "usuarios", idUsuario), {
            "preferencias.idDireccionSeleccionada": id,
            "registro.actualizacion": serverTimestamp(),
          });
        },
        async encriptarPassword(password) {
          const secretKey = "some-unique-key";
          const simpleCrypto = new SimpleCrypto(secretKey);
          const passwordCrypt = simpleCrypto.encrypt(password);
          return passwordCrypt;
        },
        async desencriptarPassword(password) {
          const secretKey = "some-unique-key";
          const simpleCrypto = new SimpleCrypto(secretKey);
          const passwordDecrypt = simpleCrypto.decrypt(password);
          return passwordDecrypt;
        },
        async actualizarPassword(id, password) {
          await updateDoc(doc(db, "usuarios", id), {
            password: password,
            "registro.actualizacion": serverTimestamp(),
          });
        },
        async correoDisponible(correo) {
          const q = query(
            collection(db, "usuarios"),
            where("correo", "==", correo)
          );
          const querySnapshot = await getDocs(q);
          return querySnapshot.docs.length > 0 ? true : null;
        },
        async documentoDisponible(numero) {
          const q = query(
            collection(db, "usuarios"),
            where("documento.numero", "==", numero)
          );
          const querySnapshot = await getDocs(q);
          return querySnapshot.docs.length > 0 ? true : false;
        },
        async alternarNotificaciones(id, esAceptada, estado) {
          await updateDoc(doc(db, "usuarios", id), {
            "preferencias.notificacionesPush.esAceptada": esAceptada,
            "preferencias.notificacionesPush.estado": estado,
            "registro.actualizacion": serverTimestamp(),
          });
        },
      },
    },
    password: {
      recuperar: {
        funciones: {
          async validarCorreo(correo) {
            const q = query(
              collection(db, "usuarios"),
              where("correo", "==", correo)
            );
            const querySnapshot = await getDocs(q);
            return querySnapshot.docs.length > 0
              ? querySnapshot.docs[0].data().id
              : null;
          },
          async crear(idUsuario) {
            const refPasword = doc(collection(db, "passwords_recuperadas"));
            await setDoc(doc(db, "passwords_recuperadas", refPasword.id), {
              id: refPasword.id,
              registro: {
                idUsuario: idUsuario,
                creacion: serverTimestamp(),
                actualizacion: serverTimestamp(),
              },
            });
            return true;
          },
        },
      },
    },
    producto: {
      objeto: class {
        constructor() {
          return {
            id: "",
            nombre: "",
            descripcion: "",
            categoria: "",
            marca: "",
            cantidad: 0,
            precio: 0,
            imagenes: [],
            registro: {
              idUsuario: "",
              estado: "Creado",
              creacion: serverTimestamp(),
              actualizacion: serverTimestamp(),
            },
          };
        }
      },
      funciones: {
        async crear(producto) {
          await setDoc(doc(db, "productos", producto.id), producto);
        },
        async actualizar(producto) {
          await updateDoc(doc(db, "productos", producto.id), producto);
        },
        async obtener(id) {
          const q = query(
            collection(db, "productos"),
            where("id", "==", id),
            where("registro.estado", "in", ["Creado", "Editado"]),
            limit(1)
          );
          const querySnapshot = await getDocs(q);
          return querySnapshot.docs.length > 0
            ? querySnapshot.docs[0].data()
            : null;
        },
        async actualizarImagenes(id, imagenes) {
          await updateDoc(doc(db, "productos", id), {
            imagenes: imagenes,
          });
        },
        async eliminar(id) {
          await updateDoc(doc(db, "productos", id), {
            "registro.estado": "Eliminado",
            "registro.actualizado": serverTimestamp(),
          });
        },
        async generarID() {
          const refProducto = doc(collection(db, "productos"));
          return refProducto.id;
        },
        cambiarNombre(nombre) {
          let nombreNormalizado = nombre
            .normalize("NFD")
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase();
          let nombreUrl = nombreNormalizado.replace(/[^a-z0-9]+/g, "-");
          nombreUrl = nombreUrl.replace(/^-+|-+$/g, "");
          return nombreUrl;
        },
      },
    },
    favorito: {
      objeto: class {
        constructor() {
          return {
            id: "",
            idProducto: "",
            registro: {
              idUsuario: "",
              creacion: serverTimestamp(),
              actualizacion: serverTimestamp(),
            },
          };
        }
      },
      funciones: {
        async crear(favorito) {
          await setDoc(doc(db, "favoritos", favorito.id), favorito);
        },
        async validar(idProducto, idUsuario) {
          const q = query(
            collection(db, "favoritos"),
            where("idProducto", "==", idProducto),
            where("registro.idUsuario", "==", idUsuario),
            limit(1)
          );
          const querySnapshot = await getDocs(q);
          return querySnapshot.docs.length > 0 ? true : null;
        },
        async eliminar(id) {
          await deleteDoc(doc(db, "favoritos", id));
        },
        async generarID() {
          const refFavorito = doc(collection(db, "favoritos"));
          return refFavorito.id;
        },
        async obtenerRegistros(idUsuario) {
          const q = query(
            collection(db, "favoritos"),
            where("registro.idUsuario", "==", idUsuario),
            orderBy("registro.creacion", "desc")
          );
          const querySnapshot = await getDocs(q);
          return querySnapshot.docs.length > 0
            ? querySnapshot.docs.map((doc) => doc.data())
            : [];
        },
      },
    },
    direccion: {
      objeto: class {
        constructor() {
          return {
            id: "",
            nombre: "",
            tipo: "",
            ubicacion: {
              pais: "Colombia",
              departamento: "",
              ciudad: "",
              barrio: "",
            },
            calle: {
              nombre: "",
              tipo: "",
              noTieneNumero: false,
              numero: {
                campo1: "",
                campo2: "",
              },
              complemento: "",
            },
            telefono: 0,
            referencias: "",
            registro: {
              idUsuario: "",
              creacion: serverTimestamp(),
              actualizacion: serverTimestamp(),
            },
          };
        }
      },
      funciones: {
        async crear(direccion) {
          await setDoc(doc(db, "direcciones", direccion.id), direccion);
        },
        async actualizar(id, direccion) {
          await updateDoc(doc(db, "direcciones", id), direccion);
        },
        async obtener(id) {
          const docSnap = await getDoc(doc(db, "direcciones", id));
          return docSnap.exists() ? docSnap.data() : null;
        },
        async obtenerSeleccionada(idUsuario) {
          const q = query(
            collection(db, "usuarios"),
            where("id", "==", idUsuario),
            where("registro.estado", "in", ["Creado", "Editado"]),
            limit(1)
          );
          const querySnapshot = await getDocs(q);
          if (querySnapshot.docs.length > 0) {
            return querySnapshot.docs[0].data().preferencias
              .idDireccionSeleccionada
              ? querySnapshot.docs[0].data().preferencias
                  .idDireccionSeleccionada
              : "";
          } else {
            return null;
          }
        },
        async validarUsuario(id, idUsuario) {
          const q = query(
            collection(db, "direcciones"),
            where("id", "==", id),
            where("registro.idUsuario", "==", idUsuario),
            limit(1)
          );
          const querySnapshot = await getDocs(q);
          return querySnapshot.docs.length > 0
            ? querySnapshot.docs[0].data()
            : null;
        },
        async obtenerRegistros(idUsuario) {
          const q = query(
            collection(db, "direcciones"),
            where("registro.idUsuario", "==", idUsuario),
            orderBy("registro.creacion", "desc")
          );
          const querySnapshot = await getDocs(q);
          return querySnapshot.docs.length > 0
            ? querySnapshot.docs.map((doc) => doc.data())
            : [];
        },
        async obtenerLimite(idUsuario) {
          const q = query(
            collection(db, "direcciones"),
            where("registro.idUsuario", "==", idUsuario)
          );
          const querySnapshot = await getDocs(q);
          return querySnapshot.docs.length === 4 ? true : false;
        },
        async generarID() {
          const refDireccion = doc(collection(db, "direcciones"));
          return refDireccion.id;
        },
        async eliminar(id) {
          await deleteDoc(doc(db, "direcciones", id));
        },
      },
    },
    notificacion: {
      objeto: class {
        constructor() {
          return {
            id: "",
            idUsuario: {
              destinatario: "",
              remitente: "",
            },
            titulo: "",
            contenido: "",
            url: "",
            esLeida: false,
            modulo: "",
            registro: {
              creacion: serverTimestamp(),
              actualizacion: serverTimestamp(),
            },
          };
        }
      },
      funciones: {
        async obtener(id) {
          const docSnap = await getDoc(doc(db, "notificaciones", id));
          return docSnap.exists() ? docSnap.data() : null;
        },
        async validarUsuario(id, idUsuario) {
          const q = query(
            collection(db, "notificaciones"),
            where("id", "==", id),
            where("idUsuario.destinatario", "==", idUsuario),
            limit(1)
          );
          const querySnapshot = await getDocs(q);
          return querySnapshot.docs.length > 0
            ? querySnapshot.docs[0].data()
            : null;
        },
        async marcarLeida(id, esLeida) {
          if (!esLeida) {
            await updateDoc(doc(db, "notificaciones", id), {
              esLeida: true,
              "registro.actualizacion": serverTimestamp(),
            });
          }
        },
        async marcarTodasLeidas(idUsuario) {
          const q = query(
            collection(db, "notificaciones"),
            where("idUsuario.destinatario", "==", idUsuario),
            where("esLeida", "==", false)
          );
          const querySnapshot = await getDocs(q);
          await Promise.all(
            querySnapshot.docs.map(async (notificacion) => {
              return await updateDoc(
                doc(db, "notificaciones", notificacion.id),
                {
                  esLeida: true,
                  "registro.actualizacion": serverTimestamp(),
                }
              );
            })
          );
        },
        async obtenerRegistros(idUsuario) {
          const q = query(
            collection(db, "notificaciones"),
            where("idUsuario.destinatario", "==", idUsuario),
            orderBy("registro.creacion", "desc")
          );
          const querySnapshot = await getDocs(q);
          return querySnapshot.docs.length > 0
            ? querySnapshot.docs.map((doc) => doc.data())
            : [];
        },
      },
    },
    notificacionToken: {
      objeto: class {
        constructor() {
          return {
            id: "",
            token: "",
            temas: ["Publicidad", "Actualizaciones-App"],
            registro: {
              idUsuario: "",
              creacion: serverTimestamp(),
              actualizacion: serverTimestamp(),
            },
          };
        }
      },
      funciones: {
        async validar(idUsuario) {
          const q = query(
            collection(db, "notificaciones_tokens"),
            where("registro.idUsuario", "==", idUsuario),
            orderBy("registro.creacion", "desc"),
            limit(1)
          );
          const querySnapshot = await getDocs(q);
          return querySnapshot.docs.length > 0
            ? querySnapshot.docs[0].data()
            : null;
        },
        async generarID() {
          const refToken = doc(collection(db, "notificaciones_tokens"));
          return refToken.id;
        },
        async crear(token) {
          await setDoc(doc(db, "notificaciones_tokens", token.id), token);
          return true;
        },
        async obtener() {
          return await getToken(messaging, {
            vapidKey:
              "BBqmKAnaz7XIPnj6vNK4vlVdy1-3zmaBSQfphGeDos483g8huEjlAHbKXKHJUwl7mtbfRiInVn-QiCB9wPCG0wA",
          });
        },
        async eliminarFCM(token) {
          try {
            await deleteToken(messaging, token);
          } catch (error) {
            if (error.code === "messaging/token-unsubscribe-failed") {
              console.error(
                "Error al desuscribirse del token FCM: Token no encontrado en FCM."
              );
            } else {
              console.error(
                "Error desconocido al eliminar el token FCM:",
                error
              );
            }
          }
        },
        async eliminarBD(id) {
          await deleteDoc(doc(db, "notificaciones_tokens", id));
        },
      },
    },
    orden: {
      registro: {
        objeto: class {
          constructor() {
            return {
              id: "",
              numero: 0,
              comprador: {
                nombre: "",
                apellido: "",
                documento: {
                  tipo: "",
                  numero: "",
                },
                telefono: 0,
              },
              direccion: {
                id: "",
                nombre: "",
                telefono: 0,
                referencias: "",
                ubicacion: {
                  pais: "",
                  departamento: "",
                  ciudad: "",
                  barrio: "",
                },
                calle: {
                  nombre: "",
                  tipo: "",
                  noTieneNumero: false,
                  numero: {
                    campo1: "",
                    campo2: "",
                  },
                  complemento: "",
                },
              },
              estado: "Pendiente",
              registro: {
                idUsuario: "",
                filtro: "",
                creacion: serverTimestamp(),
                actualizacion: serverTimestamp(),
                estado: {
                  pagada: {
                    idUsuario: "",
                    observaciones: "",
                    fecha: serverTimestamp(),
                    filtro: "",
                  },
                  enviada: {
                    idUsuario: "",
                    observaciones: "",
                    fecha: serverTimestamp(),
                    filtro: "",
                  },
                  cancelada: {
                    idUsuario: "",
                    observaciones: "",
                    fecha: serverTimestamp(),
                    filtro: "",
                  },
                  completada: {
                    idUsuario: "",
                    observaciones: "",
                    fecha: serverTimestamp(),
                    filtro: "",
                  },
                },
              },
            };
          }
        },
        funciones: {
          async crear(orden) {
            await setDoc(doc(db, "ordenes", orden.id), orden);
          },
          async actualizarEstado(id, estado) {
            if (estado === "Confirmada") {
              await updateDoc(doc(db, "ordenes", id), {
                "registro.filtro": new Date().toLocaleDateString("en-CA"),
                estado: estado,
                "registro.actualizacion": serverTimestamp(),
              });
            } else {
              await updateDoc(doc(db, "ordenes", id), {
                estado: estado,
                "registro.actualizacion": serverTimestamp(),
              });
            }
          },
          async generarNumero() {
            const q = query(
              collection(db, "ordenes"),
              orderBy("registro.creacion", "desc"),
              limit(1)
            );
            const querySnapshot = await getDocs(q);
            return querySnapshot.docs.length > 0
              ? querySnapshot.docs[0].data().numero + 1
              : 1;
          },
          async generarID() {
            const refOrden = doc(collection(db, "ordenes"));
            return refOrden.id;
          },
          async obtener(idOrden, idUsuario, esAdmin) {
            let q = query(
              collection(db, "ordenes"),
              where("id", "==", idOrden),
              where("registro.idUsuario", "==", idUsuario),
              orderBy("registro.creacion", "desc"),
              limit(1)
            );
            if (esAdmin) {
              q = query(
                collection(db, "ordenes"),
                where("id", "==", idOrden),
                orderBy("registro.creacion", "desc"),
                limit(1)
              );
            }
            const querySnapshot = await getDocs(q);
            return querySnapshot.docs.length > 0
              ? querySnapshot.docs[0].data()
              : null;
          },
          async obtenerUltima(idUsuario) {
            const q = query(
              collection(db, "ordenes"),
              where("registro.idUsuario", "==", idUsuario),
              where("estado", "==", "Pendiente"),
              orderBy("registro.creacion", "desc"),
              limit(1)
            );
            const querySnapshot = await getDocs(q);
            return querySnapshot.docs.length > 0
              ? querySnapshot.docs[0].data()
              : null;
          },
          async seleccionarDireccion(idOrden, direccion) {
            await updateDoc(doc(db, "ordenes", idOrden), {
              direccion: direccion,
              "registro.actualizacion": serverTimestamp(),
            });
          },
          async guardarComprador(id, comprador) {
            await updateDoc(doc(db, "ordenes", id), {
              comprador: comprador,
              "registro.actualizacion": serverTimestamp(),
            });
          },
          async obtenerRegistros(idUsuario, estados) {
            const q = query(
              collection(db, "ordenes"),
              where("estado", "in", [...estados]),
              where("registro.idUsuario", "==", idUsuario),
              orderBy("registro.creacion", "desc")
            );
            const querySnapshot = await getDocs(q);
            return querySnapshot.docs.length > 0
              ? querySnapshot.docs.map((doc) => doc.data())
              : [];
          },
          async editar(id, estado, observaciones, idUsuario) {
            if (estado === "Pagada") {
              await updateDoc(doc(db, "ordenes", id), {
                estado: estado,
                "registro.estado.pagada.idUsuario": idUsuario,
                "registro.estado.pagada.observaciones": observaciones,
                "registro.estado.pagada.fecha": serverTimestamp(),
                "registro.estado.pagada.filtro": new Date().toLocaleDateString(
                  "en-CA"
                ),
              });
            } else if (estado === "Enviada") {
              await updateDoc(doc(db, "ordenes", id), {
                estado: estado,
                "registro.estado.enviada.idUsuario": idUsuario,
                "registro.estado.enviada.observaciones": observaciones,
                "registro.estado.enviada.fecha": serverTimestamp(),
                "registro.estado.enviada.filtro": new Date().toLocaleDateString(
                  "en-CA"
                ),
              });
            } else if (estado === "Completada") {
              await updateDoc(doc(db, "ordenes", id), {
                estado: estado,
                "registro.estado.completada.idUsuario": idUsuario,
                "registro.estado.completada.observaciones": observaciones,
                "registro.estado.completada.fecha": serverTimestamp(),
                "registro.estado.completada.filtro":
                  new Date().toLocaleDateString("en-CA"),
              });
            } else if (estado === "Cancelada") {
              await updateDoc(doc(db, "ordenes", id), {
                estado: estado,
                "registro.estado.cancelada.idUsuario": idUsuario,
                "registro.estado.cancelada.observaciones": observaciones,
                "registro.estado.cancelada.fecha": serverTimestamp(),
                "registro.estado.cancelada.filtro":
                  new Date().toLocaleDateString("en-CA"),
              });
            }
          },
          async eliminar(id) {
            await deleteDoc(doc(db, "ordenes", id));
          },
        },
      },
      producto: {
        objeto: class {
          constructor() {
            return {
              id: "",
              imagen: "",
              nombre: "",
              descripcion: "",
              marca: "",
              categoria: "",
              precio: 0,
              cantidad: 0,
              registro: {
                idUsuario: "",
                creacion: serverTimestamp(),
                actualizacion: serverTimestamp(),
              },
            };
          }
        },
        funciones: {
          async crear(producto, idOrden) {
            let coleccion = `/ordenes/${idOrden}/productos`;
            await setDoc(doc(db, coleccion, producto.id), producto);
          },
          async aumentarCantidad(cantidad, idOrden, idProducto) {
            let coleccion = `/ordenes/${idOrden}/productos`;
            await updateDoc(doc(db, coleccion, idProducto), {
              cantidad: cantidad,
              "registro.actualizacion": serverTimestamp(),
            });
          },
          async eliminar(idOrden, idProducto) {
            let coleccion = `/ordenes/${idOrden}/productos`;
            await deleteDoc(doc(db, coleccion, idProducto));
          },
          async validar(idOrden, idProducto) {
            let coleccion = `/ordenes/${idOrden}/productos`;
            const q = query(collection(db, coleccion));
            const querySnapshot = await getDocs(q);
            const productos =
              querySnapshot.docs.length > 0
                ? querySnapshot.docs.map((doc) => doc.data())
                : [];
            const buscarProducto =
              productos.length > 0
                ? productos.filter((item) => item.id === idProducto)
                : [];
            return buscarProducto.length > 0
              ? { ...buscarProducto[0], existeProducto: true }
              : false;
          },
          async obtenerRegistros(idOrden) {
            let coleccion = `/ordenes/${idOrden}/productos`;
            const q = query(collection(db, coleccion));
            const querySnapshot = await getDocs(q);
            return querySnapshot.docs.length > 0
              ? querySnapshot.docs.map((doc) => doc.data())
              : [];
          },
          async obtenerCantidad(idOrden) {
            let coleccion = `/ordenes/${idOrden}/productos`;
            const q = query(collection(db, coleccion));
            const querySnapshot = await getDocs(q);
            return querySnapshot.docs.length > 0
              ? querySnapshot.docs
                  .map((doc) => doc.data().cantidad)
                  .reduce((total, item) => total + item, 0)
              : 0;
          },
          async obtenerTotal(idOrden) {
            let coleccion = `/ordenes/${idOrden}/productos`;
            const q = query(collection(db, coleccion));
            const querySnapshot = await getDocs(q);
            return querySnapshot.docs.length > 0
              ? querySnapshot.docs
                  .map((doc) => doc.data().cantidad * doc.data().precio)
                  .reduce((total, item) => total + item, 0)
              : 0;
          },
        },
      },
    },
    marcas: [
      "LG",
      "Samsung",
      "Haceb",
      "Electrolux",
      "Whirlpool",
      "Mabe",
      "Calcomanías",
      "Genéricos",
      "Refrigeración",
    ],
    estadosOrden: [
      "En Proceso",
      "Pagada",
      "Enviada",
      "Completada",
      "Cancelada",
      "Confirmada",
    ],
    categorias: [
      "Aires Acondicionados",
      "Neveras",
      "Lavadoras",
      "Repuestos",
      "Herramientas",
    ],
    titulo: "DOMUN - Soluciones en Refrigeración S.A.S.",
    tiposDocumento: ["Cédula de Ciudadanía", "Cédula de Extranjería", "NIT"],
    tiposCalle: [
      "Avenida",
      "Avenida Calle",
      "Avenida Carrera",
      "Calle",
      "Carrera",
      "Circular",
      "Circunvalar",
      "Diagonal",
      "Manzana",
      "Transversal",
      "Vía",
    ],
    alertaToast(tipo = "success", texto = "", posicion = "top") {
      let icono = "checkmark-circle";
      let color = "#fff";
      if (tipo === "error") {
        icono = "alert-circle";
      } else if (tipo === "warning") {
        icono = "warning";
        color = "#222";
      } else if (tipo === "info" || tipo === "default") {
        icono = "information-circle";
      }
      Vue.$toast.open({
        type: tipo,
        message: `
          <div class="d-flex align-items-center gap-1">
            <ion-icon name="${icono}" style="font-size: 18pt; color: ${color}"></ion-icon>
            <a class="fw-bold text-decoration-none" style="word-break: break-all; font-size: 13px; color: ${color}">${texto}</a>
          </div>
        `,
        position: posicion,
        duration: 3000,
      });
    },
    async alertaSwal(tipo = "info", titulo = "", msg = "", error = "") {
      let contenido = "";
      if (tipo == "success" && msg != "") {
        contenido = `
        <div class="p-2 rounded border text-center">
          <p style="color: #222">${msg}</p>
        </div>
        `;
      } else if (tipo == "info" && msg != "") {
        contenido = `
        <div class="p-2 rounded border text-center">
          <p style="color: #222">${msg}</p>
        </div>
        `;
      } else if (tipo == "warning" && msg != "") {
        contenido = `
        <div class="p-2 rounded border text-center">
          <p style="color: #222">${msg}</p>
        </div>
        `;
      } else if (tipo == "question" && msg != "") {
        contenido = `
        <div class="p-2 rounded border text-center">
          <p style="color: #222">${msg}</p>
        </div>
        `;
      } else if (tipo == "error") {
        contenido = `
        <div class="p-2 rounded border text-center">
          <p style="color: #222">${msg}</p>
        </div>
        `;
      }
      await Swal.fire({
        icon: tipo,
        background: "rgba(255, 255, 255)",
        title: '<h5 class="text-dark">' + titulo + "</h5>",
        html: contenido,
        confirmButtonText: "Entendido",
        showConfirmButton: true,
        showCancelButton: false,
        reverseButtons: false,
        buttonsStyling: false,
        customClass: {
          confirmButton: "btn btn-primary btn-md fw-bold mb-2",
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
      });
    },
    ocultarCaracteres(value, count) {
      if (value.length > count) {
        return "*".repeat(value.length - count) + value.slice(-count);
      }
      return value;
    },
    modificarBusqueda(nombre) {
      let nombreUrl = nombre
        .toLowerCase()
        .replace(/[^a-z0-9áéíóúüñ]+/g, "-")
        .replace(/^-+|-+$/g, "");
      return nombreUrl;
    },
    removerGuiones(texto) {
      return texto.replace(/-/g, " ");
    },
    buscarCategoria(categoria) {
      let texto = this.removerGuiones(categoria);
      return texto !== "herramientas de equipo"
        ? this.categorias.find(
            (item) => item.toLowerCase() === texto.toLowerCase()
          )
        : "Herramientas";
    },
    buscarMarca(marca) {
      return marca !== "calcomanias" &&
        marca !== "genericos" &&
        marca !== "refrigeracion"
        ? this.marcas.find((item) => item.toLowerCase() === marca.toLowerCase())
        : marca === "calcomanias"
        ? "Calcomanías"
        : marca === "genericos"
        ? "Genéricos"
        : "Refrigeración";
    },
    detectarDispositivo() {
      const mobile = {
        Android: function () {
          return navigator.userAgent.match(/Android/i);
        },
        BlackBerry: function () {
          return navigator.userAgent.match(/BlackBerry/i);
        },
        iOS: function () {
          return navigator.userAgent.match(/iPhone|iPad|iPod/i);
        },
        Opera: function () {
          return navigator.userAgent.match(/Opera Mini/i);
        },
        Windows: function () {
          return navigator.userAgent.match(/IEMobile/i);
        },
        any: function () {
          return (
            mobile.Android() ||
            mobile.BlackBerry() ||
            mobile.iOS() ||
            mobile.Opera() ||
            mobile.Windows()
          );
        },
      };
      return mobile.any();
    },
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});
