<template>
    <div class="NavbarUsuario">
        <div class="dropdown d-none d-md-block">
            <a href="#" class="me-1" role="button" data-bs-toggle="dropdown" aria-expanded="false" title="Usuario">
                <img :src="foto"
                    :class="{ 'photo_user rounded-pill border border-light': this.usuario.foto, 'photo_default': !this.usuario.foto }"
                    alt="foto-perfil" loading="lazy" />
            </a>
            <ul class="dropdown-menu dropdown-menu-lg-end mt-2 shadow-sm">
                <Acciones />
            </ul>
        </div>
        <a href="javascript:void(0)" class="d-block d-md-none" @click="abrirOffCanvas()" title="Usuario">
            <img :class="{ 'filter_white': !this.usuario.id && !this.usuario.foto, 'rounded-pill border border-light': this.usuario.foto }"
                :src="foto" alt="foto-perfil" width="29px" loading="lazy" />
        </a>
        <!-- OFFCANVAS MOVIL -->
        <template v-if="esMovil">
            <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasActions"
                aria-labelledby="offcanvasActionsLabel">
                <div class="offcanvas-header">
                    <img src="@/assets/img/general_logo_domun.webp" width="150px" alt="logo" loading="lazy"
                        title="Logo" />
                    <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"
                        title="Cerrar"></button>
                </div>
                <div class="offcanvas-body">
                    <Acciones @cargando="obtenerCargando" :class="{ 'container_loading': cargando }" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import { auth, db } from '@/firebase';
import {
    doc,
    onSnapshot
} from "firebase/firestore";
import { onAuthStateChanged } from "firebase/auth";
import { Offcanvas } from "bootstrap";
import Acciones from './Acciones.vue';
export default {
    name: "NavbarUsuario",

    components: {
        Acciones
    },

    data() {
        return {
            usuario: {
                id: "",
                foto: "",
                nombre: "",
                correo: "",
            },
            esMovil: false,
            cargando: false,
        }
    },

    computed: {
        foto() {
            if (this.usuario.id) {
                return this.usuario.foto ? this.usuario.foto : require('@/assets/img/general_foto_perfil_defecto.webp');
            } else {
                return require("@/assets/img/navbar_icono_usuario.webp");
            }
        }
    },

    async created() {
        onAuthStateChanged(auth, async () => {
            let usuario = auth.currentUser;
            if (usuario) {
                let usuarioDB = await this.$store.state.usuario.funciones.obtener(usuario.uid);
                if (usuarioDB) {
                    await this.obtenerUsuario(usuarioDB.id);
                }
            }
        });
    },

    methods: {
        abrirOffCanvas() {
            this.esMovil = true;
            setTimeout(() => {
                new Offcanvas((`#offcanvasActions`), {
                    backdrop: true
                }).show();
            }, 25);
        },

        async obtenerUsuario(id) {
            if (id) {
                onSnapshot(doc(db, "usuarios", id), async (doc) => {
                    this.usuario.id = doc.id ? doc.id : "";
                    this.usuario.foto = doc.data() ? doc.data().foto : "";
                    this.usuario.nombre = doc.data() ? doc.data().nombre + " " + doc.data().apellido : "";
                });
            }
        },

        obtenerCargando(data) {
            this.cargando = data;
        }
    }
}
</script>

<style scoped>
.filter_white {
    filter: brightness(0) saturate(100%) invert(98%) sepia(1%) saturate(184%) hue-rotate(113deg) brightness(116%) contrast(100%);
}

.photo_user {
    width: 32px;
}

a img {
    cursor: pointer;
    transition: transform 0.3s ease;
}

a img:hover {
    transform: scale(1.05);
}

.photo_default {
    width: 28px;
    height: 27px;
}

.dropdown-menu {
    width: 300px !important;
    padding-top: 0px;
    padding-bottom: 0px;
    border: 1px solid var(--colorseccion1);
    border-radius: 0px;
}

@media (max-width: 768px) {
    .dropdown-menu {
        display: none;
    }

    .offcanvas-body {
        padding: 0px;
    }
}
</style>