<template>
    <div class="GeneralBarraCarga">
        <div class="text-center">
            <i class="fas fa-circle-notch fa-spin text-primary" :style="{  'font-size': `${tamano}pt` }"></i>
        </div>
    </div>
</template>

<script>
export default {
    name: "GeneralBarraCarga",

    props: {
        tamano: {
            type: Number,
            default: 30
        }
    }
}
</script>

<style scoped>
.fas.fa-circle-notch {
    font-size: 30pt;
}
</style>