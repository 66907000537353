<template>
    <div class="NavbarDomun">
        <!-- HEADER 1 -->
        <div v-if="!ocultarElementos" class="header_1 grid_app">
            <div></div>
            <div class="d-flex justify-content-between flex-row">
                <div class="d-flex justify-content-start align-items-center text-primary"
                    title="Email: comercial@domunsas.com">
                    <img src="@/assets/img/navbar_icono_correo.webp" width="20px" height="15px" alt="icono-correo"
                        loading="lazy" />
                    <span>Email:</span> comercial@domunsas.com
                </div>
                <div class="d-flex justify-content-end align-items-center text-primary"
                    title="WhatsApp: +57 3223019638">
                    <img src="@/assets/img/navbar_icono_whatsapp.webp" width="20px" height="20px" alt="icono-whatsapp"
                        loading="lazy" />
                    <span>WhatsApp:</span> +57 3223019638
                </div>
            </div>
            <div></div>
        </div>
        <!-- HEADER 2 -->
        <div class="header_2 grid_app">
            <div></div>
            <div class="d-flex justify-content-between flex-row">
                <router-link :to="{ name: 'Inicio' }" class="d-flex align-items-center brand"
                    title="Página Principal de DOMUN">
                    <img src="@/assets/img/general_logo_domun.webp" width="195px" height="36px" alt="logo"
                        loading="lazy" />
                </router-link>
                <template v-if="!ocultarElementos">
                    <Buscador class="d-flex align-items-center" />
                    <div class="options_nav">
                        <Notificaciones />
                        <Favoritos />
                        <Bolsa />
                        <Usuario />
                    </div>
                </template>
            </div>
            <div></div>
        </div>
        <!-- HEADER 3 -->
        <div v-if="!ocultarElementos" class="header_3 grid_app">
            <div></div>
            <div class="d-flex justify-content-between">
                <div class="d-flex justify-content-start align-items-center gap-4">
                    <div class="btn_menu" data-bs-toggle="offcanvas" href="#offcanvasMenuPrincipal" role="button"
                        aria-controls="offcanvasMenuPrincipal" title="Abrir Menú">
                        <img src="@/assets/img/navbar_icono_categorias.webp" width="217px" height="44px"
                            alt="boton-categorias" loading="lazy" />
                    </div>
                    <div class="d-flex align-items-center gap-5 nav_1">
                        <router-link :to="{ name: 'Inicio' }" class="text-light text-decoration-none fw-bold"
                            title="Inicio">Inicio</router-link>
                        <router-link :to="{ name: 'AcercaDeSobreNosotros' }"
                            class="text-light text-decoration-none fw-bold" title="Sobre Nosotros">
                            Sobre Nosotros
                        </router-link>
                    </div>
                </div>
                <a class="d-flex align-items-center text-light text-decoration-none me-1" data-bs-toggle="offcanvas"
                    href="#offcanvasMenuAcercaDe" role="button" aria-controls="offcanvasMenuAcercaDe" title="Acerca de">
                    Acerca de
                </a>
            </div>
            <div></div>
        </div>
        <!-- HEADER MOVIL -->
        <div class="header_sm" :class="{ 'fixed-top': !ocultarElementos }">
            <div class="d-flex pt-3 pb-3"
                :class="{ 'justify-content-between': !ocultarElementos, 'ms-3': ocultarElementos }" id="header_sm">
                <div class="d-flex align-items-center justify-content-start gap-3 ms-3" id="brand-navbar">
                    <template v-if="!ocultarElementos">
                        <div data-bs-toggle="offcanvas" href="#offcanvasMenuPrincipal" role="button"
                            aria-controls="offcanvasMenuPrincipal" title="Abrir Menú">
                            <img src="@/assets/img/navbar_icono_menu_desplegable.webp" width="22px" alt="icono-menu"
                                loading="lazy" />
                        </div>
                    </template>
                    <router-link :to="{ name: 'Inicio' }" title="Página Principal de DOMUN">
                        <img class="filter_white" :class="{ 'brand_sm': !ocultarElementos }"
                            src="@/assets/img/general_logo_domun.webp" width="130px" alt="logo" loading="lazy" />
                    </router-link>
                </div>
                <template v-if="!ocultarElementos">
                    <div class="d-flex gap-3 align-items-center">
                        <Buscador :indice="Number(1)" />
                        <Notificaciones id="notifications-navbar" />
                        <Favoritos id="favorites-navbar" />
                        <Bolsa id="buys-navbar" />
                        <Usuario id="user-navbar" class="me-3" />
                    </div>
                </template>
            </div>
        </div>
        <!-- OFFCANVAS - MENU PRINCIPAL -->
        <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasMenuPrincipal"
            aria-labelledby="offcanvasMenuPrincipalLabel">
            <div class="offcanvas-header">
                <img src="@/assets/img/general_logo_domun.webp" width="150px" alt="logo" loading="lazy" title="Logo" />
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"
                    title="Cerrar"></button>
            </div>
            <div class="offcanvas-body">
                <div class="item_menu_2">
                    <router-link :to="{ name: 'Inicio' }" class="text-decoration-none text-dark" title="Inicio">
                        <div data-bs-toggle="offcanvas" href="#offcanvasMenuPrincipal" role="button"
                            aria-controls="offcanvasMenuPrincipal">
                            <img src="@/assets/img/navbar_icono_inicio.webp" width="30px" height="30px"
                                alt="icono-inicio" loading="lazy" />
                            <span>Inicio</span>
                        </div>
                    </router-link>
                </div>
                <div v-for="(categoria, index) of categorias" :key="index"
                    :class="{ 'item_menu': categoria.nombre.conFormato !== 'Herramientas de Equipo', 'item_menu_2': categoria.nombre.conFormato === 'Herramientas de Equipo' }">
                    <template v-if="categoria.nombre.conFormato === 'Herramientas de Equipo'">
                        <router-link :to="{ name: 'ProductosCategorias', params: { categoria: categoria.url } }"
                            class="text-decoration-none text-dark" :title="categoria.nombre.conFormato">
                            <div data-bs-toggle="offcanvas" href="#offcanvasMenuPrincipal" role="button"
                                aria-controls="offcanvasMenuPrincipal">
                                <img :src="require(`@/assets/img/navbar_icono_${categoria.nombre.sinFormato}.webp`)"
                                    :width="categoria.dimensiones.ancho" :height="categoria.dimensiones.alto"
                                    :alt="'icono-' + categoria.nombre.sinFormato" loading="lazy" />
                                <span>{{ categoria.nombre.conFormato }}</span>
                            </div>
                        </router-link>
                    </template>
                    <template v-else>
                        <router-link :to="{ name: 'ProductosCategorias', params: { categoria: categoria.url } }"
                            class="text-decoration-none text-dark" :title="categoria.nombre.conFormato">
                            <div class="category" data-bs-toggle="offcanvas" href="#offcanvasMenuPrincipal"
                                role="button" aria-controls="offcanvasMenuPrincipal">
                                <img :src="require(`@/assets/img/navbar_icono_${categoria.nombre.sinFormato}.webp`)"
                                    :width="categoria.dimensiones.ancho" :height="categoria.dimensiones.alto"
                                    :alt="'icono-' + categoria.nombre.sinFormato" loading="lazy" />
                                <span>{{ categoria.nombre.conFormato }}</span>
                            </div>
                        </router-link>
                        <div class="show_brands" @click="verMenuMarca(categoria.nombre.conFormato)" title="Ver Marcas">
                            <i class="fas fa-chevron-right"></i>
                        </div>
                    </template>
                </div>
                <div class="item_menu_2">
                    <div data-bs-toggle="offcanvas" href="#offcanvasMenuAcercaDe" role="button"
                        aria-controls="offcanvasMenuAcercaDe" title="Acerca de">
                        <img src="@/assets/img/navbar_icono_paginas.webp" width="28px" alt="icono-acerca-de"
                            loading="lazy" />
                        <span>Acerca de</span>
                    </div>
                </div>
            </div>
        </div>
        <!-- OFFCANVAS - MENU DINAMICO DE CATEGORIAS -->
        <Categorias v-if="esVisible" :categoria="categoria" @cerrar="cerrarMenuDinamico" />
        <!-- OFFCANVAS - ACERCA DE -->
        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasMenuAcercaDe"
            aria-labelledby="offcanvasMenuAcercaDeLabel">
            <div class="offcanvas-header">
                <h5 class="offcanvas-title" id="offcanvasMenuAcercaDeLabel">Acerca de</h5>
                <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"
                    title="Cerrar"></button>
            </div>
            <div class="offcanvas-body">
                <div class="item_menu_2">
                    <router-link :to="{ name: 'AcercaDeSobreNosotros' }" class="text-decoration-none text-dark"
                        title="Sobre Nosotros">
                        <div data-bs-dismiss="offcanvas">
                            <span>Sobre Nosotros</span>
                        </div>
                    </router-link>
                </div>
                <div class="item_menu_2">
                    <router-link :to="{ name: 'AcercaDePreguntasFrecuentes' }" class="text-decoration-none text-dark"
                        title="Preguntas Frecuentes">
                        <div data-bs-dismiss="offcanvas">
                            <span>Preguntas Frecuentes</span>
                        </div>
                    </router-link>
                </div>
                <div class="item_menu_2">
                    <router-link :to="{ name: 'AcercaDeTerminosCondiciones' }" class="text-decoration-none text-dark"
                        title="Términos y Condiciones">
                        <div data-bs-dismiss="offcanvas">
                            <span>Términos y Condiciones</span>
                        </div>
                    </router-link>
                </div>
                <div class="item_menu_2">
                    <router-link :to="{ name: 'AcercaDePoliticaCookies' }" class="text-decoration-none text-dark"
                        title="Política y Configuración de Cookies">
                        <div data-bs-dismiss="offcanvas">
                            <span>Política y Configuración de Cookies</span>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Offcanvas } from 'bootstrap';
import Buscador from './Buscador';
import Usuario from './Acciones/Usuario/Usuario.vue';
import Bolsa from './Acciones/Bolsa/Bolsa.vue';
import Categorias from './Acciones/Categorias/Categorias.vue';
import Favoritos from './Acciones/Favoritos/Favoritos.vue';
import Notificaciones from './Acciones/Notificaciones/Notificaciones.vue';
export default {
    name: "NavbarDomun",

    components: {
        Buscador,
        Usuario,
        Bolsa,
        Favoritos,
        Categorias,
        Notificaciones
    },

    data() {
        return {
            esVisible: false,
            categoria: "",
            categorias: []
        }
    },

    async created() {
        this.categorias = await Promise.all(
            this.$store.state.categorias.map(async (item) => {
                let nombre = {
                    sinFormato: item === 'Herramientas' ? 'herramientas' : item === 'Aires Acondicionados' ? 'aires' : item.toLowerCase(),
                    conFormato: item === 'Herramientas' ? 'Herramientas de Equipo' : item
                };
                let url = await this.$store.state.producto.funciones.cambiarNombre(nombre.conFormato);
                let dimensiones = await this.obtenerDimensiones(nombre.conFormato);
                return {
                    nombre: nombre,
                    url,
                    dimensiones
                }
            })
        )
    },

    computed: {
        ocultarElementos() {
            if (
                this.$route.name === 'Login' ||
                this.$route.name === 'Registrarse' ||
                this.$route.name === 'NotFound'
            ) {
                return true
            }
            return false
        }
    },

    methods: {
        verMenuMarca(categoria) {
            this.esVisible = true;
            this.categoria = categoria;
            setTimeout(() => {
                Offcanvas.getInstance(document.getElementById(`offcanvasMenuPrincipal`)).hide();
                new Offcanvas((`#offcanvasMenuCategorias`), {
                    backdrop: true
                }).show();
            }, 25);
        },

        cerrarMenuDinamico() {
            this.esVisible = false;
            this.categoria = "";
        },

        async obtenerDimensiones(item) {
            if (item === 'Aires Acondicionados') {
                return {
                    ancho: "30px",
                    alto: "30px"
                }
            }
            if (item === 'Neveras') {
                return {
                    ancho: "30px",
                    alto: "29px"
                }
            }
            if (item === 'Lavadoras') {
                return {
                    ancho: "30px",
                    alto: "30px"
                }
            }
            if (item === 'Repuestos') {
                return {
                    ancho: "30px",
                    alto: "30px"
                }
            }
            if (item === 'Herramientas de Equipo') {
                return {
                    ancho: "30px",
                    alto: "30px"
                }
            }
        }
    }
}
</script>

<style scoped>
/* HEADER 1 */
.header_1 {
    width: 100%;
    height: 37px;
    background-color: var(--colorBlanco);
    border-bottom: 2px solid var(--colorseccion1);
    font-size: 11pt;
}

.header_1 div img {
    margin-right: 10px;
}

.header_1 div span {
    color: var(--colorSecundario);
    font-weight: bold;
    margin-right: 5px;
}

/* HEADER 2 */
.header_2 {
    width: 100%;
    height: 69px;
    background-color: var(--colorBlanco);
}

.header_2 .brand {
    cursor: pointer;
    margin-right: 10px;
}

.header_2 .brand img {
    transition: transform 0.3s ease;
}

.header_2 .brand img:hover {
    transform: scale(1.05);
}

.header_2 .options_nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
}

/* HEADER 3 */
.header_3 {
    width: 100%;
    height: 48px;
    background-color: var(--colorPrincipal);
}

.header_3 .btn_menu img {
    margin-top: 4px;
}

/* HEADER MOVIL */
.header_sm {
    display: none !important;
    background-color: var(--colorPrincipal);
    overflow: hidden;
    height: auto;
}

.header_sm img,
.header_sm svg {
    cursor: pointer;
}

.filter_white {
    filter: brightness(0) saturate(100%) invert(98%) sepia(1%) saturate(184%) hue-rotate(113deg) brightness(116%) contrast(100%);
}

/* OFFCANVAS BODY */
.offcanvas-body {
    padding: 0px;
}

/* MENUS */
.item_menu {
    display: grid;
    grid-template-columns: 1fr 50px;
}

.item_menu .category {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 25px;
    padding-left: 25px;
    background-color: var(--colorseccion2);
    transition: background-color 0.3s ease-in-out;
}

.item_menu .category img {
    margin-right: 10px;
}

.item_menu .show_brands {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 25px;
    padding-left: 20px;
    background-color: #dedede;
    color: var(--colorNegro);
    transition: background-color 0.3s ease-in-out;
}

.item_menu_2 {
    color: var(--colorNegro);
    transition: background-color 0.3s ease-in-out;
}

.item_menu_2 div {
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 25px;
    padding-left: 25px;
}

.item_menu_2 img {
    margin-right: 10px;
}

.item_menu .category:hover,
.item_menu .show_brands:hover,
.item_menu_2:hover {
    background-color: var(--colorPrincipal);
    color: var(--colorBlanco);
    cursor: pointer;
    font-weight: 500;
}

.item_menu_2:hover span {
    color: var(--colorBlanco);
}

.item_menu .category:hover img,
.item_menu_2:hover img {
    filter: brightness(0) saturate(100%) invert(98%) sepia(1%) saturate(184%) hue-rotate(113deg) brightness(116%) contrast(100%);
}

#offcanvasMenuPrincipal .offcanvas-body .item_menu_2:first-child {
    display: none !important;
}

#offcanvasMenuPrincipal .offcanvas-body .item_menu_2:last-child {
    display: none !important;
}

#offcanvasMenuAcercaDe .offcanvas-body .item_menu_2:first-child {
    display: none !important;
}

@media screen and (max-width: 1200px) {
    .header_2 .options_nav {
        gap: 10px;
    }
}

@media screen and (max-width: 768px) {

    .header_1,
    .header_2,
    .header_3 {
        display: none !important;
    }

    .header_sm,
    #offcanvasMenuPrincipal .offcanvas-body .item_menu_2:first-child,
    #offcanvasMenuPrincipal .offcanvas-body .item_menu_2:last-child,
    #offcanvasMenuAcercaDe .offcanvas-body .item_menu_2:first-child {
        display: block !important;
    }
}

@media screen and (max-width: 430px) {
    .header_sm .brand_sm {
        display: none;
    }
}

@media screen and (min-width: 361px) and (max-width: 375px) {
    .header_sm .container li img {
        transform: scale(0.9);
        transition: transform 0.3s ease;
    }
}

@media screen and (min-width: 281px) and (max-width: 360px) {
    .header_sm .container li img {
        transform: scale(0.9);
        transition: transform 0.3s ease;
    }
}

@media screen and (max-width: 280px) {
    .header_sm .container li img {
        transform: scale(0.8);
        transition: transform 0.3s ease;
    }

    .header_sm .brand_sm {
        display: none;
    }
}
</style>