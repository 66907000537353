import Vue from "vue";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { auth } from "./firebase";
import { onAuthStateChanged } from "firebase/auth";

Vue.config.productionTip = false;

onAuthStateChanged(auth, () => {
  new Vue({
    router,
    store,
    render: (h) => h(App),
  }).$mount("#app");
});
